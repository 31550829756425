import React, { useState, useCallback } from "react";
import { TableCell, TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import { AppointmentSuggestion, GetAppointmentSuggestionsInput } from "../../generated/nest-graphql";
import { ApolloError } from "@apollo/client";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DateTime } from "luxon";
import { always, cond, equals, T } from "ramda";
import HomeIcon from "@material-ui/icons/Home";
import BuildTwoToneIcon from "@material-ui/icons/BuildTwoTone";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

type AppointmentOptionTypes = {
  suggestions: AppointmentSuggestion[];
  error?: ApolloError;
  called?: boolean;
  loading?: boolean;
  onClick?: any;
  suggestionToolInputs: { getAppointmentSuggestionsInput: GetAppointmentSuggestionsInput };
  timeZone: string;
};

type TablePropTypes = {
  suggestions?: AppointmentSuggestion[];
  suggestionToolInputs: { getAppointmentSuggestionsInput: GetAppointmentSuggestionsInput };
  onClick: any;
  timeZone: string;
};

const columns = [
  { label: "Technician", id: "technician", sortable: true },
  { label: "Date", id: "date" },
  { label: "Start Time", id: "startTime", sortable: true },
  // { label: "Available Techs", id: "availableTechs" },
  { label: "Score", id: "score", sortable: true },
  { label: "Drive Time To", id: "driveTimeTo", sortable: true },
  { label: "Drive Time From", id: "driveTimeFrom", sortable: true },
  { label: "Action", id: "action" },
];

const areDatesSameDay = (dateA: DateTime, dateB: DateTime) => {
  return dateA.hasSame(dateB, "day");
};

export const AppointmentOptions = ({
  suggestions = [],
  error,
  called,
  loading,
  onClick,
  suggestionToolInputs,
  timeZone,
}: AppointmentOptionTypes) => {
  let showTable = suggestions.length > 0;
  return (
    <div className="my-8 flex flex-col items-center">
      {showTable && (
        <AppointmentOptionsTable
          suggestions={suggestions}
          onClick={onClick}
          timeZone={timeZone}
          suggestionToolInputs={suggestionToolInputs}
        />
      )}
      <div className="w-4/5 text-center">
        {error && (
          <div className="mb-4">
            {error.message && error.message === "OutOfServiceAreaError"
              ? "Address is outside of a serviceable market, please try a different address"
              : "An error occurred with your appointment suggestion request. Try again, and if this continues please post to #application-bugs. Sorry about that!"}
          </div>
        )}
        {called && !loading && !showTable && !error && (
          <div className="mb-4">{`No available times for ${
            DateTime.fromJSDate(suggestionToolInputs?.getAppointmentSuggestionsInput?.startTime).toLocaleString() ??
            "the requested date"
          }, please try another.`}</div>
        )}
        {!called && (
          <>
            <div className="mb-4">
              Get appointment suggestions to find the most optimal time and technician for this job.
            </div>
            <div>
              Suggestions are meant to help reduce drive time, fit more jobs in the day, and allocate jobs equally among
              our technicians.
            </div>
          </>
        )}
      </div>
      {called && loading && <LinearProgress className="w-4/5 h-2" />}
    </div>
  );
};

function descendingComparator(a, b, orderBy) {
  if (orderBy === "score") {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else if (orderBy === "startTime") {
    if (b.startDate < a.startDate) {
      return 1;
    }
    if (b.startDate > a.startDate) {
      return -1;
    }
  } else if (orderBy === "driveTimeTo") {
    if (b.driveTimes.arrival.durationWithTrafficInSeconds < a.driveTimes.arrival.durationWithTrafficInSeconds) {
      return -1;
    }
    if (b.driveTimes.arrival.durationWithTrafficInSeconds > a.driveTimes.arrival.durationWithTrafficInSeconds) {
      return 1;
    }
  } else if (orderBy === "driveTimeFrom") {
    if (b.driveTimes.departure.durationWithTrafficInSeconds < a.driveTimes.departure.durationWithTrafficInSeconds) {
      return -1;
    }
    if (b.driveTimes.departure.durationWithTrafficInSeconds > a.driveTimes.departure.durationWithTrafficInSeconds) {
      return 1;
    }
  } else if (orderBy === "technician") {
    if (b.technician.firstName + b.technician.lastName < a.technician.firstName + a.technician.lastName) {
      return -1;
    }
    if (b.technician.firstName + b.technician.lastName > a.technician.firstName + a.technician.lastName) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const AppointmentOptionsTable = ({ suggestions = [], onClick, timeZone, suggestionToolInputs }: TablePropTypes) => {
  const [showCount, setShowCount] = useState(15);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("score");

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const showMore = () => {
    if (showCount < suggestions.length) {
      setShowCount(showCount + 15);
    }
  };

  return (
    <Paper className="flex flex-col items-center">
      <TableContainer>
        <Table size="medium" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell id={column.id} key={column.id}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      // @ts-ignore
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={(e) => handleRequestSort(column.id)}
                    >
                      {column.label} {orderBy !== column.id && <UnfoldMoreIcon />}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(suggestions, getComparator(order, orderBy))
              .slice(0, showCount)
              .map((suggestion, idx) => {
                const isSuggestionSelectedQueryDay = areDatesSameDay(
                  DateTime.fromJSDate(suggestionToolInputs.getAppointmentSuggestionsInput.startTime).setZone(timeZone),
                  DateTime.fromISO(suggestion.startDate)
                );
                const isSuggestionSameDay = areDatesSameDay(
                  DateTime.fromObject({}).setZone(timeZone),
                  DateTime.fromISO(suggestion.startDate)
                );
                const isSuggestionNextDay = areDatesSameDay(
                  DateTime.fromObject({}).plus({ days: 1 }).setZone(timeZone),
                  DateTime.fromISO(suggestion.startDate)
                );

                const dateColumnCopy = cond([
                  [() => isSuggestionSameDay, always("Today ")],
                  [() => isSuggestionNextDay, always("Tomorrow ")],
                  [T, always(DateTime.fromISO(suggestion.startDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY))],
                ])(null);

                const dayColorStyle = cond([
                  [() => isSuggestionSelectedQueryDay || isSuggestionSameDay, always({ color: "#66CD00" })],
                  [T, always(undefined)],
                ])(null);

                return (
                  <TableRow key={idx}>
                    <TableCell>{`${suggestion.technician.firstName} ${suggestion.technician.lastName}`}</TableCell>
                    <TableCell style={dayColorStyle}>{dateColumnCopy}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(suggestion.timeWindow?.startTimeWindow).toLocaleString(
                        DateTime.TIME_WITH_SHORT_OFFSET
                      )}
                    </TableCell>
                    {/*<TableCell>{suggestions.filter((x) => x.startDate === suggestion.startDate).length}</TableCell>*/}
                    <TableCell>{suggestion.score}</TableCell>
                    <TableCell
                      style={
                        suggestion.driveTimes.arrival.durationWithTrafficInSeconds > 1800 ? { color: "red" } : undefined
                      }
                    >
                      {suggestion.driveTimes.arrival.readableDurationWithTraffic}
                      {"    "}
                      {equals(suggestion.arrivalServiceLocation, suggestion.technician.serviceAreaAnchorAddress) ? (
                        <HomeIcon fontSize={"inherit"} />
                      ) : (
                        <BuildTwoToneIcon fontSize={"inherit"} />
                      )}
                    </TableCell>
                    <TableCell
                      style={
                        suggestion.driveTimes.departure.durationWithTrafficInSeconds > 1800
                          ? { color: "red" }
                          : undefined
                      }
                    >
                      {suggestion.driveTimes.departure.readableDurationWithTraffic}
                      {"    "}
                      {equals(suggestion.departureServiceLocation, suggestion.technician.serviceAreaAnchorAddress) ? (
                        <HomeIcon fontSize={"inherit"} />
                      ) : (
                        <BuildTwoToneIcon fontSize={"inherit"} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => onClick(suggestion)}>Use</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {showCount < suggestions.length && <Button onClick={() => showMore()}>view more</Button>}
    </Paper>
  );
};
