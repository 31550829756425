import React from "react";
import { useField } from "formik";
import { PartsStore, Query } from "../../generated/nest-graphql";
import { defaultTo, map, prop, propOr, descend, contains, sortWith, ascend, concat } from "ramda";
import { useQuery } from "@apollo/client";
import { GET_PARTS_STORES } from "../../graphql/queries/getPartsStores";
import { flow } from "fp-ts/lib/function";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";

type PartsStoreOption = {
  label: string;
  value: PartsStore;
};

type PartsStoreOptionType = {
  label: string;
  value: PartsStore;
};

export type PartsStoreFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  homeStore: PartsStore;
  postOnChange: (homePartsStore: PartsStore) => any;
};

export const JobPartsStoreSelectField = ({
  name,
  label,
  homeStore,
  required = false,
  postOnChange,
}: PartsStoreFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { data } = useQuery<Query, {}>(GET_PARTS_STORES);
  const homePartsStoreId = prop("id", homeStore);
  const partsStoresToOption = (partsStore: PartsStore) => {
    const { name, id } = partsStore;
    if (id === homePartsStoreId) {
      return {
        label: `Home Store: ${name}`,
        value: partsStore,
      };
    }
    return {
      label: `${name}`,
      value: partsStore,
    };
  };
  const partsStoresOptions: any = flow(
    propOr([], "getPartsStores"),
    map(partsStoresToOption),
    concat([{ value: null, label: "" }]),
    sortWith([descend(flow(prop("label"), contains("Home Store"))), ascend(prop("label"))])
  )(data);

  const filterOptions = createFilterOptions<PartsStoreOptionType>({
    matchFrom: "any",
    stringify: (option) => {
      return option.label;
    },
  });
  const onChange = (_: object, value: any) => {
    helpers.setValue(prop("value", value));
    postOnChange(prop("value", value));
  };
  if (!data || partsStoresOptions.length === 0) return null;
  return (
    <Autocomplete<PartsStoreOption>
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return defaultTo("", option.label);
      }}
      value={field.value ? partsStoresToOption(field.value) : { value: null, label: "" }}
      options={partsStoresOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
          helperText={<>{meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}</>}
        />
      )}
    />
  );
};
