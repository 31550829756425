import { pipe } from "fp-ts/lib/function";
import { applySpec, groupWith, head, isEmpty, join, map, prop, reduce, reject, sort } from "ramda";
import { exists } from "../../commonFunctions";
import { Contact, VehicleSymptom } from "../../generated/nest-graphql";
import { VehicleSymptomFormValues } from "../Forms/ContactDetailsForm";

export const getVehicleSymptomsString = (vehicleSymptoms: VehicleSymptom[] = []) => {
  return pipe(
    pipe(vehicleSymptoms, map(vehicleSymptomToVehicleSymptomFormValue)),
    sort(({ axle: a }, { axle: b }) => {
      const aSort = a ?? "";
      const bSort = b ?? "";
      return aSort.localeCompare(bSort);
    }),
    groupWith(({ axle: a }, { axle: b }) => a === b),
    map((grouping) => {
      const axle = head(grouping)?.axle;
      const axleString = axle ? "Axle: " + axle : "unknown";
      const symptoms = pipe(
        grouping,
        reject(isEmpty),
        reduce((acc, { symptom: s }) => acc.concat(s), [])
      );
      const symptomString = isEmpty(symptoms) ? "" : "Symptoms: " + join(", ", symptoms);
      return axleString + "\n" + symptomString;
    }),
    reject(isEmpty),
    join("\n")
  );
};
export const getRequestedServicesString = (requestedServices: string[] = []) => {
  return !isEmpty(requestedServices) ? "Requested Services: " + join(", ", requestedServices) : "";
};

export const concatVehicleSymptomsAndRequestedServicesStrings = (
  vehicleSymptomsString: string = "",
  requestedServicesString: string = ""
) => {
  return vehicleSymptomsString + "\n" + requestedServicesString;
};

export const getSymptoms = (contact: Contact) => {
  console.log("getSymptoms", {
    symptomTextField: contact?.lead?.vehicleInfo?.symptoms,
    vehicleSymptoms: contact?.lead?.vehicleSymptoms,
    requestedServices: contact?.lead?.requestedServices,
  });
  const symptoms = contact?.lead?.vehicleInfo?.symptoms;
  const requestedServices = contact?.lead?.requestedServices;
  return exists(symptoms)
    ? symptoms
    : concatVehicleSymptomsAndRequestedServicesStrings(
        getVehicleSymptomsString(contact?.lead?.vehicleSymptoms ?? []),
        getRequestedServicesString(requestedServices ?? [])
      );
};

export const vehicleSymptomToVehicleSymptomFormValue = (vehicleSymptom: VehicleSymptom) =>
  applySpec<VehicleSymptomFormValues>({
    axle: prop("axle"),
    symptom: prop("symptom"),
  })(vehicleSymptom);
  
export const contactDetailsSpec = (formData) =>
  applySpec({
    firstName: prop("firstName"),
    lastName: prop("lastName"),
    email: prop("email"),
    phoneNumber: prop("phoneNumber"),
    timeZone: prop("timeZone"),
    outreachMedium: prop("outreachMedium"),
    lead: {
      zip: prop("zip"),
      address: prop("address"),
      hearAboutUs: prop("hearAboutUs"),
      market: prop("market"),
      source: prop("source"),
      vehicleInfo: {
        frontPadLife: prop("frontPadLife"),
        licensePlate: prop("licensePlate"),
        licensePlateState: prop("licensePlateState"),
        make: prop("make"),
        model: prop("model"),
        odometer: prop("odometer"),
        rearPadLife: prop("rearPadLife"),
        vin: prop("vin"),
        year: prop("year"),
        extraInfo: prop("extraInfo"),
        symptoms: prop("symptoms"),
        customerExpectation: prop("customerExpectation"),
        additionalNotes: prop("additionalNotes"),
        frontBrakeSymptoms: prop("frontBrakeSymptoms"),
        rearBrakeSymptoms: prop("rearBrakeSymptoms"),
      },
      vehicleSymptoms: prop("vehicleSymptoms"),
    },
    fleet: {
      name: prop("fleetName"),
    },
    taxExemptEin: prop("taxExemptEin"),
  })(formData);
