import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { map, path, prop } from "ramda";
import { ItemInput, Job } from "../../generated/nest-graphql";
import { flow } from "fp-ts/lib/function";
import { technicianCopySpec } from "./technicianCopySpec";
import { ItemsTableType } from "../Items/ItemsTable";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "./servicesSpec";
import {
  concatVehicleSymptomsAndRequestedServicesStrings,
  getVehicleSymptomsString,
  getRequestedServicesString,
} from "../Contacts/contactDetailsFormHelpers";
import { exists } from "../../commonFunctions";

export const getSymptoms = (job: Job) => {
  const symptoms = job?.vehicleInfo?.symptoms;
  const vehicleSymptoms = job?.vehicleSymptoms ?? [];
  const requestedServices = job?.requestedServices ?? [];
  return exists(symptoms)
    ? symptoms
    : concatVehicleSymptomsAndRequestedServicesStrings(
        getVehicleSymptomsString(vehicleSymptoms),
        getRequestedServicesString(requestedServices)
      );
};

export const itemsSpec = (items) =>
  map((item: ItemsTableType): ItemInput => {
    const {
      product,
      partsCost,
      partsStore,
      vendorPartsCost,
      amount,
      description,
      laborCost,
      name,
      isInEstimate,
      partNumber,
    } = item;
    return {
      product: product,
      partsCost,
      partsStore: prop("id", partsStore),
      partNumber,
      isInEstimate,
      name,
      laborCost,
      description,
      amount,
      vendorPartsCost,
    };
  }, items);

export const jobDetailsSpec = (formData: JobDetailsFormValues) => {
  const {
    contact,
    partsLocation,
    partsOrdered,
    partsOrderedTimestamp,
    partsOrderingIssue,
    estimate,
    partsOrderNumber,
    extraInfo,
    symptoms,
    frontPadLife,
    licensePlate,
    licensePlateState,
    make,
    taxable,
    model,
    odometer,
    rearPadLife,
    partsNotes,
    market,
    vin,
    year,
    appointmentId,
    description,
    items,
    jobName,
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    type,
    status,
    appointmentInfo,
    withdrawReason,
    withdrawExtraInfo,
    additionalNotes,
    customerExpectation,
    frontBrakeSymptoms,
    rearBrakeSymptoms,
    jobCausedWarrantyCheck,
    warrantyCheckReason,
    warrantyCheckReasonExtraInfo,
    partsStore,
    services,
    discounts,
    promoCodes,
    serviceCatalogueUsed,
    priceInfo,
    partsLeadTimeInDays,
    callForPartsTicketNumber,
    vehicleSymptoms,
    requestedServices,
  } = formData;

  return {
    contact: prop("id", contact),
    partsStore: prop("id", partsStore),
    description,
    items: items && itemsSpec(items),
    jobName,
    estimate,
    taxable,
    withdrawReason,
    withdrawExtraInfo,
    market,
    appointment: appointmentId,
    partsInfo: {
      partsLocation,
      partsOrdered,
      partsOrderedTimestamp,
      partsOrderNumber,
      partsNotes,
      partsOrderingIssue,
      callForPartsTicketNumber,
    },
    privateNotes,
    jobCausedWarrantyCheck,
    warrantyCheckReason,
    warrantyCheckReasonExtraInfo,
    serviceLocation,
    serviceLocationNotes,
    status,
    type,
    appointmentInfo: {
      subject: prop("subject", appointmentInfo),
      startDate: prop("startDate", appointmentInfo),
      endDate: prop("endDate", appointmentInfo),
      technician: path(["technician", "id"], appointmentInfo),
      allDay: prop("allDay", appointmentInfo),
      timeWindow: {
        startTimeWindow: prop("startTimeWindow", appointmentInfo),
        endTimeWindow: prop("endTimeWindow", appointmentInfo),
      },
      technicianCopy: flow(prop("technician"), technicianCopySpec)(appointmentInfo),
      timeZone: prop("timeZone", appointmentInfo),
      overrideOutsideServiceZone: prop("overrideOutsideServiceZone", appointmentInfo),
      availabilitySnapshotId: prop("availabilitySnapshotId", appointmentInfo),
      appointmentSnapshotId: prop("appointmentSnapshotId", appointmentInfo),
    },
    vehicleInfo: {
      frontPadLife,
      licensePlate,
      licensePlateState,
      make,
      model,
      odometer,
      rearPadLife,
      extraInfo,
      symptoms,
      vin,
      year,
      additionalNotes,
      customerExpectation,
      frontBrakeSymptoms,
      rearBrakeSymptoms,
    },
    services: servicesToPossibleEjiServiceInput(services),
    discounts: discountsToEjiDiscountInput(discounts),
    promoCodes: promoCodesToPriceInfoInput(promoCodes),
    serviceCatalogueUsed,
    partsLeadTimeInDays,
    pricingConfig: pricingConfigToEjiPricingConfigInput(priceInfo?.pricingConfig),
    vehicleSymptoms,
    requestedServices,
  };
};
