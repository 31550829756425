import * as React from "react";
import { useRef } from "react";
import { VehicleInfoFormSection } from "../VehicleInfo/VehicleInfoFormSection";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { allPass, pathOr, prop, startsWith } from "ramda";
import { SelectField } from "../FormFields/SelectField";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { TextField } from "../FormFields/TextField";
import { getFieldValueFromMarkets, MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { SaveCancelRow } from "../SaveCancelRow";
import { Space } from "../Space";
import { flow } from "fp-ts/lib/function";
import { ContactDetailsFormValues } from "./ContactDetailsForm";
import { useMarkets } from "../../hooks/useMarkets";
import { adminSources } from "../adminSources";

export const CreateInquiryRequestForm: React.FC<{
  values: ContactDetailsFormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  resetForm: any;
  initialValues: ContactDetailsFormValues;
  isSubmitting: boolean;
  isValid: boolean;
}> = ({ values, setFieldValue, resetForm, initialValues, isSubmitting, isValid }) => {
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const markets = useMarkets();

  return (
    <div ref={currentRef as any}>
      <DetailViewContainer title={"Contact info"}>
        <div className="grid grid-cols-3 gap-4">
          <TextField name={"email"} label={"Contact Email"} type={"email"} helperText={"Updates contact email"} />
          <div className="row-span-3">
            <TextField name={"serviceLocationNotes"} label={"Service Location Notes"} multiline={true} rows={6} />
          </div>
          <MarketFieldSelect
            name={"market"}
            required={true}
            postOnChange={(value: any) => {
                if (value === null) return;
                setFieldValue("timeZone", prop(value, getFieldValueFromMarkets(markets, "timeZone", value)));
            }}
          />
          <LocationSearchInput name={"address"} label={"Address"} />
          <SelectField name={"source"} options={adminSources} label={"Source"} required={true} />
        </div>
      </DetailViewContainer>
      <VehicleInfoFormSection />
      <DetailViewContainer title={"Job Details"}>
        <div className="grid grid-cols-2 gap-4">
          <TextField name={"description"} label={"Description"} multiline={true} rows={4} />
          <TextField
            name={"privateNotes"}
            label={"Private Notes"}
            multiline={true}
            rows={4}
            helperText="Internal notes used by ops and technicians."
          />
          {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
            <TextField required={true} name={"jobCausedWarrantyCheck"} label={"Job Prior to Warranty Check"} />
          )}
          {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
            <SelectField
              name={"warrantyCheckReason"}
              label={"Warranty Check Reason"}
              options={[
                "Part defect",
                "Technician did not install parts correctly",
                "Technician misdiagnosed repair",
                "Customer expectations set incorrectly",
                "Abnormal customer driving behavior",
                "Other",
              ]}
            />
          )}
          {allPass([
            flow(pathOr("", ["type"]), startsWith("Warranty")),
            flow(pathOr("", ["warrantyCheckReason"]), startsWith("Other")),
          ])(values) && <TextField name={"warrantyCheckReasonExtraInfo"} label={"Additional Warranty Check Details"} />}
        </div>
      </DetailViewContainer>
      <Space />
      <SaveCancelRow
        width={width}
        offsetLeft={left}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onCancel={() => resetForm(initialValues)}
      />
    </div>
  );
};
