import React from "react";
import { ContactDetailsForm } from "../../components/Forms/ContactDetailsForm";
import { Container } from "../../components/Container";
import { cleanObject } from "../../lib/functions";
import { keys, pipe } from "ramda";
import { contactDetailsSpec } from "../../components/Contacts/contactDetailsFormHelpers";
import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationOpsAdminCreateInquiryRequestArgs,
  MutationPrivateCreateLeadArgs,
} from "../../generated/nest-graphql";
import { CREATE_LEAD } from "../../graphql/mutations/createLead";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { OPS_ADMIN_CREATE_INQUIRY_REQUEST } from "../../graphql/mutations/opsAdminCreateInquiryRequest";
import { flow } from "fp-ts/lib/function";
import { inquiryRequestSpec } from "../../components/InquiryRequest/spec";

const AddContactPage: React.FC = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [opsAdminCreateInquiryRequest] = useMutation<Mutation, MutationOpsAdminCreateInquiryRequestArgs>(
    OPS_ADMIN_CREATE_INQUIRY_REQUEST
  );

  const opsAdminCreateInquiryRequestOnSubmit = async (values) => {
    const updates = flow(inquiryRequestSpec, cleanObject)(values);
    if (keys(updates).length) {
      const { data } = await opsAdminCreateInquiryRequest({
        variables: {
          createInquiryRequestInput: updates,
        },
      });
      const id = data.opsAdminCreateInquiryRequest?.serviceRequest?.id;
      showSuccess({ message: "Successfully Added Contact" });
      await history.push(`/jobs/${id}`);
    }
  };

  const [privateCreateLead] = useMutation<Mutation, MutationPrivateCreateLeadArgs>(CREATE_LEAD);
  const privateCreateLeadOnSubmit = async (values, formikHelpers) => {
    // @ts-ignore
    const updates = pipe(
      contactDetailsSpec,
      // @ts-ignore
      cleanObject
    )(values);
    if (keys(updates).length) {
      const { data } = await privateCreateLead({
        variables: {
          // @ts-ignore
          createLeadInput: updates,
        },
      });
      const id = data.privateCreateLead.id;
      showSuccess({ message: "Successfully Added Contact" });
      await history.push(`/contacts/${id}?tab=jobs`);
    }
  };
  return (
    <div>
      <Container>
        <div className="mt-16" />
        <div>Add Contacts Page</div>
        <ContactDetailsForm
          initialValues={{
            zip: "",
            phoneNumber: "",
            market: "",
            make: "",
            outreachMedium: "Inbound Call",
            hearAboutUs: "",
            lastName: "",
            firstName: "",
            email: "",
            address: "",
            extraInfo: "",
            model: "",
            source: "",
            symptoms: "",
            year: "",
            additionalNotes: "",
            customerExpectation: "",
            timeZone: "",
            fleetName: "",
            isFleet: false,
            taxExemptEin: "",
            isTaxExempt: false,
            frontBrakeSymptoms: [],
            rearBrakeSymptoms: [],
            vehicleSymptoms: [],
            requestedServices: [],
          }}
          onSubmit={opsAdminCreateInquiryRequestOnSubmit}
        />
      </Container>
    </div>
  );
};

export default AddContactPage;
