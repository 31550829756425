import * as React from "react";
import { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { TextField } from "../FormFields/TextField";
import DebouncedCurrencyInput from "../FormFields/DebouncedCurrencyInput";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreatePaymentIntentArgs } from "../../generated/nest-graphql";
import { CREATE_PAYMENT_INTENT } from "../../graphql/mutations/createPaymentIntent";
import { DateInput } from "../FormFields/DateInput";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { useFormikContext } from "formik";
import { AddPaymentFormValues } from "./AddPaymentForm";
import { Button } from "../Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { cleanCurrency } from "../../lib/functions";
import { v4 } from "uuid";
import currency from "currency.js";

export const CreditCardFields: React.FC<{
  invoiceId: string;
  contactId: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  subTotal: string;
  totalTax: string;
  partsTotal: string;
  laborTotal: string;
  feesTotal: string;
  discountTotal: string;
  finalPartsTotal: string;
  finalLaborTotal: string;
  finalFeesTotal: string;
  finalSubTotal: string;
  postCreditCard: () => void;
}> = ({
  invoiceId,
  contactId,
  postCreditCard,
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  totalTax,
  partsTotal,
  laborTotal,
  feesTotal,
  discountTotal,
  finalPartsTotal,
  finalLaborTotal,
  finalFeesTotal,
  finalSubTotal,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const { values, isValid, setFieldValue } = useFormikContext<AddPaymentFormValues>();
  const [createPaymentIntent, { data, loading }] = useMutation<Mutation, MutationCreatePaymentIntentArgs>(
    CREATE_PAYMENT_INTENT
  );
  const [submitting, setIsSubmitting] = useState(false);
  const showSuccess = useShowSuccess();
  const { amount, tip, ...rest } = values;
  const amountWithTip = currency(tip).add(currency(amount)).toString();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const result = await createPaymentIntent({
        variables: {
          createPaymentInput: {
            status: "processing",
            invoice: invoiceId,
            laborCost,
            partsCost,
            partsTax,
            laborTax,
            subTotal,
            totalTax,
            partsTotal,
            laborTotal,
            feesTotal,
            discountTotal,
            finalPartsTotal,
            finalLaborTotal,
            finalFeesTotal,
            finalSubTotal,
            contact: contactId,
            amount: cleanCurrency(amountWithTip),
            invoicePrice: cleanCurrency(amount),
            tip: cleanCurrency(tip),
            ...rest,
          },
          idempotencyKey: v4(),
        },
      });

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        result?.data?.createPaymentIntent?.client_secret,
        {
          payment_method: {
            billing_details: {
              name: values.payer,
            },
            card: elements.getElement(CardElement),
          },
        }
      );
      if (error) {
        // Show error to your customer (e.g., insufficient funds)
        console.error({ error });
        setCardErrorMessage(error.message);
        setIsSubmitting(false);
      } else {
        // The payment has been processed!
        if (paymentIntent.status === "succeeded") {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          setFieldValue("paymentIntentId", paymentIntent.id);
          showSuccess({ message: "Payment Submitted Successfully!" });
          await postCreditCard();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="grid gap-4">
      <TextField name={"payer"} required={true} label={"Name On Card"} />
      <DateInput name={"receivedDate"} label={"Received Date"} required={true} />
      <DebouncedCurrencyInput name={"amount"} label={"Amount"} required={true} />
      <TextField name={"refNumber"} label={"Ref #/Check #"} />
      <TextField name={"memo"} label={"Memo"} />
      <DebouncedCurrencyInput name={"tip"} label={"Tip"} />
      <CheckBoxField name={"sendReceipt"} label={"Send Receipt?"} />
      <CardElement
        options={{
          classes: {
            base: "bg-gray-900 text-white px-4 py-2 border rounded border-gray-900",
          },
          style: {
            base: {
              color: "white",
            },
          },
        }}
      />
      {cardErrorMessage && <div style={{ color: "red" }}>{cardErrorMessage}</div>}
      <Button type={"button"} onClick={handleSubmit} disabled={!isValid || submitting || !stripe || loading}>
        {submitting ? <FontAwesomeIcon icon={faSpinner as any} spin={true} /> : "Submit"}
      </Button>
    </div>
  );
};
