// date-fns format
export const DATE_TIME_FORMAT: string = "MM/dd/yyyy hh:mm a";

export const DATE_FORMAT: string = "MM/dd/yyyy";

export const INITIAL_JOB_STATUS: string = "Service Request In";
export const UNSCHEDULED_JOB_STATUS: string = "Unscheduled";

export const JOB_STATUS_MASTER_LIST = [
  INITIAL_JOB_STATUS,
  UNSCHEDULED_JOB_STATUS,
  "Self-Scheduling Link Sent",
  "Scheduled",
  "Open: En Route",
  "Open: In-Progress",
  "Open: Ready For Billing",
  "Closed",
  "Withdrawn",
  "Withdrawn: Reschedule",
];

export const TIME_FORMAT: string = "hh:mm a";
export const RESCHEDULE_REASONS = [
  "Customer: No show or no response",
  "Customer: Could not access vehicle",
  "Customer: Schedule conflict",
  "Customer: Did not provide VIN or LP",
  "Customer: Did not provide reason",
  "Customer: Weather",
  "Customer: Other",
  "HQ: Scheduling Mistake",
  "HQ: Technician running behind",
  "HQ: Incorrect parts",
  "HQ: Part availability",
  "HQ: Technician availability",
  "HQ: Technician could not complete service",
  "HQ: Weather",
  "HQ: Route optimization",
  "HQ: Re-quote",
  "HQ: Overbooked Time Slot",
  "HQ: Drive time too long",
  "HQ: Other",
];

export const WITHDRAW_REASONS = [
  "Customer: Decided on alternative option",
  "Customer: Price too high",
  "Customer: Rebooked for another time",
  "Customer: No show or no response",
  "Customer: Refuses to pay",
  "Customer: Could not access vehicle",
  "Customer: Schedule conflict",
  /*"Customer: Services no longer needed",*/
  "Customer: Did not provide VIN or LP",
  "Customer: Did not provide reason",
  /*"HQ: Technician running behind",*/
  "HQ: Incorrect parts",
  "HQ: Part availability",
  "HQ: Technician availability",
  "HQ: Technician could not complete service",
  "HQ: Weather",
  "HQ: Duplicate Job Booked",
  "HQ: Drive time too long",
  "Other: Freeform",
];

export const EJI_DISCOUNT_TYPE_PERCENT = "Percent";
export const EJI_DISCOUNT_TYPE_FLAT = "Flat";
export const DISCOUNT_LIMIT_PERCENT = 40;
export const DISCOUNT_LIMIT_FLAT = 400;

export const DISCOUNT_REASONS = [
  "Marketing: Radio discount",
  "Marketing: Student / Teacher Discount",
  "Marketing: Friends & Family Discount",
  "Sales: Pricing Negotiation",
  "Sales: Competitor Price Match",
  "Sales: Increase Tech Utilization - Flash Sales",
  "Sales: Offer discount to close the lead immediately",
  "Sales: Follow Up Discount to incentivize lead to book in a certain time frame",
  "Support: Technician was late",
  "Support: Reschedule discount",
  "Support: Requote discount",
  "Support: Appeasement for poor service",
  "Support: Damage (in the case when a discount will cover cost of damages)",
  "Support: Misdiagnosis",
  "Support: Customer was charged the wrong amount",
  "Support: Customer discount was not applied",
];

export const SERVICE_CATALOGUE_USED_ITEMS = "Items";

export const SERVICE_CATALOGUE_USED_SERVICES = "Services";

export const DEFAULT_SERVICE_CATALOGUE_USED:
  | typeof SERVICE_CATALOGUE_USED_ITEMS
  | typeof SERVICE_CATALOGUE_USED_SERVICES = SERVICE_CATALOGUE_USED_SERVICES;

export const WARN_UNITS = 25;

export const MAX_VENDOR_UNIT_COST = 10000; //set way above normal 1500 rather than removing
export const WARN_VENDOR_UNIT_COST = 1000;

export const ROW_LIMIT = 50;

export const US_STATES = [
  { value: 'Alabama', label: 'AL' },
  { value: 'Alaska', label: 'AK' },
  { value: 'Arizona', label: 'AZ' },
  { value: 'Arkansas', label: 'AR' },
  { value: 'California', label: 'CA' },
  { value: 'Colorado', label: 'CO' },
  { value: 'Connecticut', label: 'CT' },
  { value: 'Delaware', label: 'DE' },
  { value: 'Florida', label: 'FL' },
  { value: 'Georgia', label: 'GA' },
  { value: 'Hawaii', label: 'HI' },
  { value: 'Idaho', label: 'ID' },
  { value: 'Illinois', label: 'IL' },
  { value: 'Indiana', label: 'IN' },
  { value: 'Iowa', label: 'IA' },
  { value: 'Kansas', label: 'KS' },
  { value: 'Kentucky', label: 'KY' },
  { value: 'Louisiana', label: 'LA' },
  { value: 'Maine', label: 'ME' },
  { value: 'Maryland', label: 'MD' },
  { value: 'Massachusetts', label: 'MA' },
  { value: 'Michigan', label: 'MI' },
  { value: 'Minnesota', label: 'MN' },
  { value: 'Mississippi', label: 'MS' },
  { value: 'Missouri', label: 'MO' },
  { value: 'Montana', label: 'MT' },
  { value: 'Nebraska', label: 'NE' },
  { value: 'Nevada', label: 'NV' },
  { value: 'New Hampshire', label: 'NH' },
  { value: 'New Jersey', label: 'NJ' },
  { value: 'New Mexico', label: 'NM' },
  { value: 'New York', label: 'NY' },
  { value: 'North Carolina', label: 'NC' },
  { value: 'North Dakota', label: 'ND' },
  { value: 'Ohio', label: 'OH' },
  { value: 'Oklahoma', label: 'OK' },
  { value: 'Oregon', label: 'OR' },
  { value: 'Pennsylvania', label: 'PA' },
  { value: 'Rhode Island', label: 'RI' },
  { value: 'South Carolina', label: 'SC' },
  { value: 'South Dakota', label: 'SD' },
  { value: 'Tennessee', label: 'TN' },
  { value: 'Texas', label: 'TX' },
  { value: 'Utah', label: 'UT' },
  { value: 'Vermont', label: 'VT' },
  { value: 'Virginia', label: 'VA' },
  { value: 'Washington', label: 'WA' },
  { value: 'West Virginia', label: 'WV' },
  { value: 'Wisconsin', label: 'WI' },
  { value: 'Wyoming', label: 'WY' },
]
