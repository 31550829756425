import React from "react";
import { useMutation } from "@apollo/client";
import { CircularProgress, TextField } from "@material-ui/core";
import { Button } from "../Buttons/Button";
import { Field } from "formik";
import { VALIDATE_PROMO_CODE } from "../../graphql/mutations/validatePromoCode";
import { useShowSuccess, useShowError } from "../../redux/slices/snackbar";
import {
  pricingConfigToEjiPricingConfigInput,
  servicesToPossibleEjiServiceInput,
  promoCodesToPriceInfoInput,
  discountsToEjiDiscountInput,
} from "../specs/servicesSpec";
import { find, propEq, cond } from "ramda";
import { exists } from "../../commonFunctions";
import { Mutation } from "../../generated/nest-graphql";

export const PromoCodeSection: React.FC<{ calculatePossibleEJIPriceInfo: any; ejiType?: string; jobId?: string }> = (
  props
) => {
  const { calculatePossibleEJIPriceInfo, ejiType, jobId } = props;
  const [promoCode, setPromoCode] = React.useState("");
  const showError = useShowError();
  const show = useShowSuccess();

  const [validatePromoCode, { loading, error }] = useMutation<Mutation>(VALIDATE_PROMO_CODE, {
    variables: {
      jobId: props.jobId,
      code: promoCode,
    },
  });

  const showErrorMessage = React.useCallback((message) => {
    setPromoCode("");
    showError({ message });
    return false;
  }, []);

  const sendValidPromoAndShowSuccess = async ({ values, promo }) => {
    await calculatePossibleEJIPriceInfo({
      variables: {
        calculatePossibleEJIPriceInfoInput: {
          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
          services: servicesToPossibleEjiServiceInput(values.services),
          marketName: values.market,
          taxable: values.taxable,
          calculateAllServices: ejiType === "INVOICE",
          discounts: discountsToEjiDiscountInput(values.discounts),
          promoCodes: promoCodesToPriceInfoInput([...values.promoCodes, promo]),
        },
      },
    });
    setPromoCode("");
    show({
      message: `Promo Code: ${promoCode} was successfully added to your order!`,
    });
  };

  React.useEffect(() => {
    if (error) {
      showErrorMessage(error.message);
    }
  }, [error, showErrorMessage]);

  return (
    <>
      <Field name="promoCodes">
        {({ form: { values }, field: { value = [] }, meta }) => {
          const calculatePromoCode = async (promoCode) => {
            const response = await validatePromoCode({ variables: { code: promoCode, jobId: jobId } });
            onSubmit(response?.data?.validatePromoCode);
          };

          const onSubmit = (response) => {
            cond([
              [
                // If the code already exists in added promoCodes
                () => exists(find(propEq("code", response?.code))(meta.value)),
                () => {
                  // is there a discoiunt for every promo?
                  showErrorMessage(`Code: ${promoCode}  already applied to your order!`);
                },
              ],
              [
                // There are promos added to order and the incoming promo is not combinable
                () => exists(value) && propEq("combinable", false, response),
                () => {
                  showErrorMessage(`The Code: ${promoCode} Is Not Combinable!`);
                },
              ],
              [
                // if a code is added that is not combinable
                () => exists(find(propEq("combinable", false))(value)),
                () => {
                  showErrorMessage(`Promo Code in use is non-combinable!`);
                },
              ],
              [
                // Final check the promo code should be enabled (not necessary back end checks this)
                () => response?.status !== "enabled", // need to check if  // enabled and not a value
                // was the promo in the initial value and now it is no longer?
                () => {
                  showErrorMessage(`The Promo Code: "${promoCode}" Is Not Enabled!`);
                },
              ],
              [
                // Final check the promo code should be enabled (not necessary back end checks this)
                () => response?.status === "enabled", // need to check if  // enabled and not a value
                // was the promo in the initial value and now it is no longer?
                () => {
                  return sendValidPromoAndShowSuccess({ values, promo: response });
                },
              ],
            ])();
          };

          return (
            <>
              <PromoCodeTextInput
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                calculatePromoCode={calculatePromoCode}
                isLoading={loading}
              />
            </>
          );
        }}
      </Field>
    </>
  );
};

const PromoCodeTextInput: React.FC<{
  promoCode: string;
  setPromoCode: any;
  calculatePromoCode: any;
  isLoading?: boolean;
}> = ({ promoCode, setPromoCode, calculatePromoCode, isLoading }) => {
  return (
    <div className="flex justify-start">
      <div className="w-full md:w-1/3 flex justify-start align-middle">
        <TextField
          variant="outlined"
          placeholder="Enter Promo Code"
          value={promoCode}
          onChange={(e) => {
            setPromoCode(e.target.value);
          }}
          type="text"
          className="mr-2"
        />
        <Button
          disabled={isLoading || promoCode === ""}
          className="bg-primary ml-4"
          onClick={() => calculatePromoCode(promoCode)}
          type={"button"}
        >
          {isLoading ? <CircularProgress size={20} /> : "+ Promo Code"}
        </Button>
      </div>
    </div>
  );
};
