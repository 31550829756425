import gql from "graphql-tag";
import { itemsFields } from "./getEstimate";
import { partsStoreDetailsFields } from "../queries/getPartsStore";
import { ejiPriceInfoFields } from "./calculatePossibleEJIPriceInfo";
import { partDetailFields } from "./getPartCatalogue";
import { vehicleSymptomFields } from "./getContactDetails";

export const activityFeedFields = `activityFeed {
            actor
            id
            objectId
            verb
            createdAt
        }`;

export const preJobCheckListItemField = `{
    partCondition
    additionalInfo
} `;

export const brakePadsListItemField = `{
    padThickness {
        thickness
        unavailable
    }
    condition
}`;

export const oilLifeListItemField = `{
    nextOilChangeMileage {
        mileage
        unavailable
    }
    nextOilChangeDate {
        date
        unavailable
    }
    lastOilChangeDate {
        date
        unavailable
    }
}`;

export const tireTreadListItemField = `{
    frontRight {
        depth
        unavailable
    }
    frontLeft {
        depth
        unavailable
    }
    rearRight {
        depth
        unavailable
    }
    rearLeft {
        depth
        unavailable
    }
}`;

export const jobDetailsFields = `
        ${activityFeedFields}
        vehicleInfo {
            frontPadLife
            licensePlate
            licensePlateState
            make
            model
            odometer
            rearPadLife
            vin
            year
            symptoms
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            extraInfo
        }
        vehicleSymptoms {
            ${vehicleSymptomFields}
        }
        requestedServices
        invoice {
            id
            balanceDue
        }
        appointment {
            id
            allDay
            endDate
            startDate
            timeZone
            timeWindow {
                startTimeWindow
                endTimeWindow
            }
            subject
            technician {
                id
                firstName
                lastName
                homePartsStore {
                    id
                    storeNumber
                    name
                    vendor
                }
            }
            ${activityFeedFields}
        }
        contact {
            id
            firstName
            lastName
            taxExemptEin
            lead {
                id
                address
                hearAboutUs
                market
                source
                vehicleInfo {
                  frontPadLife
                    licensePlate
                    licensePlateState
                    make
                    model
                    odometer
                    rearPadLife
                    vin
                    year
                    extraInfo
                    symptoms
                    customerExpectation
                    additionalNotes
                    frontBrakeSymptoms
                    rearBrakeSymptoms
                }
                vehicleSymptoms {
                    ${vehicleSymptomFields}
                }
                requestedServices
                zip
            }
            email
            timeZone
            stripeCustomer
            stripePaymentMethods
        }
        feedback {
            rating
            doneBetter
            additionalComments
        }
        description
        id
        jobNumber
        ${itemsFields}
        jobName
        type
        contact {
            firstName
            lastName
        }
        serviceLocation
        partsInfo {
            partsLocation
            partsOrderNumber
            partsOrdered
            partsOrderedTimestamp
            partsNotes
            partsOrderingIssue
            callForPartsTicketNumber
        }
        estimate {
            id
        }
        partsStore {
            id
            name
            storeNumber
        }
        withdrawReason 
        withdrawExtraInfo
        withdrawnAt
        mHelpId
        jobCausedWarrantyCheck {
            id
        }
        warrantyCheckReason
        warrantyCheckReasonExtraInfo
        market
        taxable
        privateNotes
        serviceLocationNotes
        preJobCheckList {
            frontPads ${preJobCheckListItemField}
            frontPadsV2 ${brakePadsListItemField}
            frontRotors ${preJobCheckListItemField}
            rearPads ${preJobCheckListItemField}
            rearPadsV2 ${brakePadsListItemField}
            rearRotors ${preJobCheckListItemField}
            brakeFluid ${preJobCheckListItemField}
            brakeShoes ${preJobCheckListItemField}
            brakeDrums ${preJobCheckListItemField}
            calipers ${preJobCheckListItemField}
            shocksStruts ${preJobCheckListItemField}
            controlArms ${preJobCheckListItemField}
            wheelHubs ${preJobCheckListItemField}
            studs ${preJobCheckListItemField}
            lugnuts ${preJobCheckListItemField}    
            sparkPlugs ${preJobCheckListItemField}
            carBattery ${preJobCheckListItemField}
            alternator ${preJobCheckListItemField}
            engineAirFilter ${preJobCheckListItemField}
            cabinAirFilter ${preJobCheckListItemField}    
            otherBrakeParts ${preJobCheckListItemField}
            dashboardLights ${preJobCheckListItemField}
            odometerReading ${preJobCheckListItemField}
            oilLife ${oilLifeListItemField}
            tireTread ${tireTreadListItemField}
        }
        createdAt
        updatedAt
        status
        serviceCatalogueUsed
        services {
            id
            service
            name
            inEstimate
            customerPrice
            technicianPay
            items {
                id
                serviceProductId
                productSelection {
                    name
                    part {
                        ${partDetailFields}
                    }
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
                marketLaborRateUsed
            }
            updatedAt
            createdAt
        }
        partsLeadTimeInDays
        pipedriveDealId
        priceInfo {
            ${ejiPriceInfoFields}
        }
        `;

export const GET_JOB = gql`query getJob($id: ID!) {
    getJob(id: $id) {
        ${jobDetailsFields}
    }
}`;
