import { InvoiceDetailsFormValues } from "../Forms/InvoiceDetailsForm";
import { prop } from "ramda";
import { itemsSpec } from "./jobDetailsSpec";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "./servicesSpec";
import { Invoice } from "../../generated/nest-graphql";
import {
  concatVehicleSymptomsAndRequestedServicesStrings,
  getVehicleSymptomsString,
  getRequestedServicesString,
} from "../Contacts/contactDetailsFormHelpers";
import { exists } from "../../commonFunctions";

export const getSymptoms = (invoice: Invoice) => {
  const symptoms = invoice?.vehicleInfo?.symptoms;
  const vehicleSymptoms = invoice?.vehicleSymptoms ?? [];
  const requestedServices = invoice?.requestedServices ?? [];
  return exists(symptoms)
    ? symptoms
    : concatVehicleSymptomsAndRequestedServicesStrings(
        getVehicleSymptomsString(vehicleSymptoms),
        getRequestedServicesString(requestedServices)
      );
};

export const invoiceDetailsSpec = (formData: InvoiceDetailsFormValues) => {
  const {
    contact,
    market,
    job,
    technician,
    estimate,
    taxable,
    serviceLocation,
    privateNotes,
    items,
    status,
    issuedDate,
    dueDate,
    model,
    year,
    customerMessage,
    extraInfo,
    frontPadLife,
    licensePlate,
    licensePlateState,
    make,
    odometer,
    rearPadLife,
    symptoms,
    vin,
    additionalNotes,
    customerExpectation,
    frontBrakeSymptoms,
    rearBrakeSymptoms,
    services,
    discounts,
    promoCodes,
    serviceCatalogueUsed,
    priceInfo,
    vehicleSymptoms,
    requestedServices,
  } = formData;
  return {
    contact: prop("id", contact),
    customerMessage,
    dueDate,
    issuedDate,
    items: items && itemsSpec(items),
    privateNotes,
    taxable,
    market,
    serviceLocation,
    status,
    estimate,
    technician: prop("id", technician),
    job,
    vehicleInfo: {
      year,
      extraInfo,
      frontPadLife,
      licensePlate,
      licensePlateState,
      make,
      model,
      odometer,
      rearPadLife,
      symptoms,
      vin,
      additionalNotes,
      customerExpectation,
      frontBrakeSymptoms,
      rearBrakeSymptoms,
    },
    services: servicesToPossibleEjiServiceInput(services),
    discounts: discountsToEjiDiscountInput(discounts),
    promoCodes: promoCodesToPriceInfoInput(promoCodes),
    serviceCatalogueUsed,
    pricingConfig: pricingConfigToEjiPricingConfigInput(priceInfo?.pricingConfig),
    vehicleSymptoms,
    requestedServices,
  };
};
