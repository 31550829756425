import gql from "graphql-tag";

export const GET_MARKET_FOR_ADDRESS = gql`
  query getMarketForAddress($address: String!) {
    getMarketForAddress(address: $address) {
      id
      name
      timeZone
      activeServiceArea
      activeServiceAreaGeoJson
      marketArea
      marketAreaGeoJson
    }
  }
`;
