import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { Box } from "@material-ui/core";
import { TextField } from "../FormFields/TextField";
import { PhoneNumberField } from "../FormFields/PhoneNumberField";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { SubmitButton } from "../Buttons/SubmitButton";
import { path, isNil } from "ramda";
import TimeZoneSelect from "../FormFields/TimeZoneSelect";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { Space } from "../Space";
import { SelectField } from "../FormFields/SelectField";
import { TaxInput } from "../FormFields/TaxInput";
import { Field } from "formik";

export type MarketFormValues = {
  name: string;
  timeZone: string;
  phoneNumber: string;
  email: string;
  address: string;
  businessID: string;
  activeServiceAreaGeoJson: string;
  marketAreaGeoJson: string;
  salesTaxType: string;
  partsTaxRate: number;
  laborTaxRate: number;
};

Yup.addMethod<Yup.StringSchema>(Yup.string, "validateGeoJson", function (msg: string) {
  return this.test("validateGeoJson", msg, function (value) {
    try {
      if (value) {
        const geoJson = JSON.parse(value);
        const features: any = path(["features"], geoJson);
        if (features.length > 1) {
          return this.createError({
            path: this.path,
            message: 'Only 1 service area can be saved at a time ("features" list is greater than 1)',
          });
        }
        const geometry = path(["features", 0, "geometry"], geoJson);
        return !isNil(geometry) || this.createError({ path: this.path, message: msg });
      }
      return true;
    } catch (error) {
      return this.createError({ path: this.path, message: msg });
    }
  });
});

const marketSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  timeZone: Yup.string().required("Required"),
  activeServiceAreaGeoJson: Yup.string()
    //@ts-ignore
    .validateGeoJson("Not valid GeoJson"),
  marketAreaGeoJson: Yup.string()
    //@ts-ignore
    .validateGeoJson("Not valid GeoJson"),
  salesTaxType: Yup.string().required("Required"),
  partsTaxRate: Yup.number().required("Required"),
  laborTaxRate: Yup.number().required("Required"),
});

export const MarketForm = ({ initialValues, onSubmit }) => {
  const currentRef = React.useRef();
  const { width, left } = useContainerDimensions(currentRef);
  return (
    <div ref={currentRef}>
      <Formik<MarketFormValues> initialValues={initialValues} validationSchema={marketSchema} onSubmit={onSubmit}>
        {({ isSubmitting, isValid }) => {
          return (
            <Form>
              <DetailViewContainer title={"Market Info"}>
                <Box className="grid grid-cols-2 gap-2">
                  <TextField name={"name"} label={"Name"} required={true} />
                  <TimeZoneSelect name={"timeZone"} label={"Timezone"} />
                  <PhoneNumberField name={"phoneNumber"} label={"Phone Number"} />
                  <TextField name={"email"} label={"Email"} type={"email"} />
                  <LocationSearchInput name={"address"} label={"Address"} />
                  <TextField name={"businessID"} label={"Business ID"} />
                  <Box className={"col-span-2"}>
                    <TextField name={"marketAreaGeoJson"} label={"Market Area"} multiline={true} />
                    <TextField name={"activeServiceAreaGeoJson"} label={"Active Service Area"} multiline={true} />
                  </Box>
                </Box>
              </DetailViewContainer>
              <DetailViewContainer title={"Market Tax Info"}>
                <Box className={"grid grid-cols-3 gap-2"}>
                  <SelectField
                    label={"Sales Tax Type"}
                    name={"salesTaxType"}
                    options={["Origin", "Destination"]}
                    required={true}
                    hideNone={true}
                  />
                  <Field name={"partsTaxRate"}>
                    {({ field, form, meta }) => (
                      <TaxInput
                        name={field.name}
                        error={!!meta.error}
                        value={field.value}
                        label={"Parts Tax Rate"}
                        required={true}
                        onChange={(value) => {
                          form.setFieldValue(field.name, Number(value));
                        }}
                      />
                    )}
                  </Field>
                  <Field name={"laborTaxRate"}>
                    {({ field, form, meta }) => (
                      <TaxInput
                        name={field.name}
                        error={!!meta.error}
                        value={field.value}
                        label={"Labor Tax Rate"}
                        required={true}
                        onChange={(value) => {
                          form.setFieldValue(field.name, Number(value));
                        }}
                      />
                    )}
                  </Field>
                </Box>
              </DetailViewContainer>
              <Space />
              <div
                className="bg-black"
                style={{
                  position: "fixed",
                  width,
                  left: left,
                  bottom: 0,
                  zIndex: 10,
                }}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex justify-end">
                    <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                      Save
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
