import * as React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../Buttons/SubmitButton";
import { TextField } from "../FormFields/TextField";
import { PhoneNumberField } from "../FormFields/PhoneNumberField";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { SelectField } from "../FormFields/SelectField";
import TimeZoneSelect from "../FormFields/TimeZoneSelect";
import { TechnicianRoleSelectField } from "../FormFields/TechnicianRoleSelectField";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { PartsStoreSelectField } from "../FormFields/PartsStoreSelectField";
import { useMarkets } from "../../hooks/useMarkets";
import { TechnicianRole, PartsStore, SchedulingDay } from "../../generated/nest-graphql";
import { flow } from "fp-ts/lib/function";
import { add, defaultTo, find, prop, propEq, propOr, pluck, isNil } from "ramda";
import PayMultiplierInput from "../FormFields/PayMultiplierInput";
import { getFieldValueFromMarkets } from "../FormFields/MarketFieldSelect";
import { TechnicianSchedulingDays } from "../FormFields/TechnicianSchedulingDays";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { Space } from "../Space";
import { isAfter } from "date-fns/fp";
import { DateTime } from "luxon";
import { usePartsStores } from "../../hooks/usePartsStores";

export type TechnicianDetailsFormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  payMultiplier: number;
  isActive: boolean;
  email: string;
  market: string;
  role: TechnicianRole;
  password?: string;
  confirmPassword?: string;
  timeZone: string;
  homePartsStore: PartsStore;
  schedulingDays: SchedulingDay[];
  onWayMessage: string;
  serviceAreaAnchorAddress?: string;
};
export const TechnicianDetailsForm: React.FC<{
  setPassword?: boolean;
  onSubmit: any;
  initialValues: TechnicianDetailsFormValues;
}> = ({ setPassword = false, onSubmit, initialValues }) => {
  const markets = useMarkets();
  const partsStores = usePartsStores();
  const currentRef = React.useRef();
  const { width, left } = useContainerDimensions(currentRef);

  const schedulingDateModifier = (date: Date) => {
    // for 00:00:00 formatting
    return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
  };

  Yup.addMethod<Yup.ObjectSchema>(Yup.object, "validateSchedulingDay", function (msg: string) {
    return this.test("validateSchedulingDay", msg, function (value: any) {
      try {
        if (value) {
          const dayOfWeek: any = propOr(null, "dayOfWeek", value);
          if (isNil(dayOfWeek)) {
            return this.createError({ path: this.path, message: "Invalid schedule: a week day is missing." });
          }
          const startTime: any = propOr(null, "startTime", value);
          if (isNil(startTime)) {
            return this.createError({ path: this.path, message: "Invalid schedule: a start time is missing." });
          }
          const endTime: any = propOr(null, "endTime", value);
          if (isNil(endTime)) {
            return this.createError({ path: this.path, message: "Invalid schedule: a end time is missing." });
          }

          const start = DateTime.fromFormat(startTime, "HH:mm:ss");
          if (!start.isValid) {
            return this.createError({
              path: this.path,
              message: `Invalid Start Time of ${startTime} for ${dayOfWeek}.`,
            });
          }
          const startDate = start.toJSDate();
          const end = DateTime.fromFormat(endTime, "HH:mm:ss");
          if (!end.isValid) {
            return this.createError({ path: this.path, message: `Invalid End Time of ${endTime} for ${dayOfWeek}.` });
          }
          const endDate = end.toJSDate();
          if (isAfter(endDate, startDate)) {
            return this.createError({
              path: this.path,
              message: `Invalid schedule: the ending time for ${dayOfWeek} must be after the starting time.`,
            });
          }
          return true;
        }
        return true;
      } catch (error) {
        return this.createError({ path: this.path, message: msg });
      }
    });
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    payMultiplier: Yup.string().required("Required"),
    isActive: Yup.boolean(),
    email: Yup.string().email().required("Required"),
    market: Yup.string().required("Required"),
    role: Yup.object().required("Required"),
    timeZone: Yup.string().required("Required"),
    homePartsStore: Yup.object().required("Required"),
    schedulingDays: Yup.array()
      .of(
        Yup.object()
          .shape({
            dayOfWeek: Yup.string().required(),
            startTime: Yup.string().required(),
            endTime: Yup.string().required(),
          })
          // @ts-ignore
          .validateSchedulingDay("Invalid schedule")
      )
      .required("Must have all days scheduled.")
      .min(7, "Must have all days of the week."),
    onWayMessage: Yup.string().nullable(),
    serviceAreaAnchorAddress: Yup.string().nullable(),
  });

  return (
    <div ref={currentRef}>
      <Formik<TechnicianDetailsFormValues>
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid, values, setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return (
            <Form>
              <DetailViewContainer title={"Technician Info"}>
                <div className="grid grid-cols-2 gap-2">
                  <TextField name={"firstName"} label={"First Name"} required={true} />
                  <TextField name={"lastName"} label={"Last Name"} required={true} />
                  <TextField name={"email"} label={"Email"} required={true} type={"email"} />
                  <PayMultiplierInput name={"payMultiplier"} label={"Pay Multiplier"} readOnly={true} />
                  <SelectField
                    postOnChange={(currentMarket) => {
                      const timeZone = flow(
                        find(propEq("name", currentMarket)),
                        // @ts-ignore
                        prop("timeZone")
                      )(markets);

                      timeZone && setFieldValue("timeZone", timeZone);
                      timeZone &&
                        setFieldValue(
                          "timeZone",
                          prop(currentMarket, getFieldValueFromMarkets(markets, "timeZone", currentMarket))
                        );

                      setFieldValue("homePartsStore", null);
                    }}
                    name={"market"}
                    options={defaultTo([], markets && pluck("name", markets))}
                    required={true}
                    label={"Market"}
                  />
                  <PhoneNumberField name={"phoneNumber"} required={true} label={"Phone Number"} />
                  <TechnicianRoleSelectField
                    name={"role"}
                    label={"Technician Role"}
                    required={true}
                    postOnChange={(role) => {
                      const base = 1;
                      const updateMult = flow(propOr(base, "payMultiplier"), add(-1), add(base))(role);
                      setFieldValue("payMultiplier", updateMult);
                    }}
                  />
                  <Field name="homePartsStore">
                    {({ field, meta, form }) => (
                      <PartsStoreSelectField
                        name={"homePartsStore"}
                        label={"Home Parts Store"}
                        value={field.value}
                        required={true}
                        options={partsStores.filter((partsStore) => partsStore.market?.name === values.market)}
                        onChange={(_, newValue: any) => {
                          form.setFieldValue("homePartsStore", newValue?.value);
                        }}
                        error={meta.error}
                      />
                    )}
                  </Field>
                  <TimeZoneSelect name={"timeZone"} label={"Time Zone"} />
                  <div className="grid grid-rows-3">
                    <div>
                      <LocationSearchInput name={"serviceAreaAnchorAddress"} label={"Service Area Anchor Address"} />
                    </div>
                    <div className="row-span-2">
                      <TextField name={"onWayMessage"} label={"On Way Message"} multiline rows={4} />
                    </div>
                  </div>
                  <CheckBoxField name={"isActive"} label={"isActive"} />
                  {setPassword && (
                    <div className="col-span-2 grid grid-cols-2 gap-2">
                      <TextField name={"password"} label={"Password"} type={"password"} required={true} />
                      <TextField
                        name={"confirmPassword"}
                        label={"Confirm Password"}
                        type={"password"}
                        required={true}
                      />
                    </div>
                  )}
                </div>
              </DetailViewContainer>
              <DetailViewContainer title={"Scheduling Hours"}>
                <TechnicianSchedulingDays
                  values={values.schedulingDays}
                  name={"schedulingDays"}
                  dateModifier={schedulingDateModifier}
                  setFieldValue={setFieldValue}
                />
              </DetailViewContainer>
              <Space />
              <div
                className="bg-black"
                style={{
                  position: "fixed",
                  width,
                  left: left,
                  bottom: 0,
                  zIndex: 10,
                }}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex justify-end">
                    <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                      Save
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
