import { EstimateDetailsFormValues } from "../Forms/EstimateDetailsForm";
import { applySpec, path, prop } from "ramda";
import { itemsSpec } from "../specs/jobDetailsSpec";
import { flow } from "fp-ts/lib/function";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "./servicesSpec";
import { Estimate } from "../../generated/nest-graphql";
import {
  concatVehicleSymptomsAndRequestedServicesStrings,
  getVehicleSymptomsString,
  getRequestedServicesString,
} from "../Contacts/contactDetailsFormHelpers";
import { exists } from "../../commonFunctions";

export const getSymptoms = (estimate: Estimate) => {
  const symptoms = estimate?.vehicleInfo?.symptoms;
  const vehicleSymptoms = estimate?.vehicleSymptoms ?? [];
  const requestedServices = estimate?.requestedServices ?? [];
  return exists(symptoms)
    ? symptoms
    : concatVehicleSymptomsAndRequestedServicesStrings(
        getVehicleSymptomsString(vehicleSymptoms),
        getRequestedServicesString(requestedServices)
      );
};

export const estimateDetailSpec = (formData: EstimateDetailsFormValues) =>
  applySpec({
    contact: path(["contact", "id"]),
    estimateNotes: prop("estimateNotes"),
    issuedDate: prop("issuedDate"),
    items: (data) => prop("items")(data) && flow(prop("items"), itemsSpec)(data),
    privateNotes: prop("privateNotes"),
    serviceLocation: prop("serviceLocation"),
    serviceLocationNotes: prop("serviceLocationNotes"),
    status: prop("status"),
    taxable: prop("taxable"),
    market: prop("market"),
    vehicleInfo: {
      frontPadLife: prop("frontPadLife"),
      licensePlate: prop("licensePlate"),
      licensePlateState: prop("licensePlateState"),
      make: prop("make"),
      model: prop("model"),
      odometer: prop("odometer"),
      rearPadLife: prop("rearPadLife"),
      vin: prop("vin"),
      year: prop("year"),
      symptoms: prop("symptoms"),
      extraInfo: prop("extraInfo"),
      customerExpectation: prop("customerExpectation"),
      additionalNotes: prop("additionalNotes"),
      frontBrakeSymptoms: prop("frontBrakeSymptoms"),
      rearBrakeSymptoms: prop("rearBrakeSymptoms"),
    },
    services: flow(prop("services"), servicesToPossibleEjiServiceInput),
    discounts: flow(prop("discounts"), discountsToEjiDiscountInput),
    promoCodes: flow(prop("promoCodes"), promoCodesToPriceInfoInput),
    serviceCatalogueUsed: prop("serviceCatalogueUsed"),
    partsLeadTimeInDays: prop("partsLeadTimeInDays"),
    pricingConfig: flow(path(["priceInfo", "pricingConfig"]), pricingConfigToEjiPricingConfigInput),
    callForPartsTicketNumber: prop("callForPartsTicketNumber"),
    partsNotes: prop("partsNotes"),
    vehicleSymptoms: prop("vehicleSymptoms"),
    requestedServices: prop("requestedServices"),
  })(formData);
