import { anyPass, complement, either, isEmpty, isNil, reject } from "ramda";

/**
 * Checks if value is not empty, null, or undefined
 * @returns boolean
 */
export const exists = (a: any): boolean => complement(either(isNil, isEmpty))(a);

/**
 * Checks if value is empty, null, or undefined
 * @returns boolean
 */
export const doesNotExist = (a: any): boolean => either(isNil, isEmpty)(a);

export const rejectBlank = (collection: string[]) => reject(anyPass([isNil, isEmpty]))(collection);
