import React, { useCallback, useState } from "react";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Button } from "../Buttons/Button";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  PartsStore,
  PossibleEjiService,
  Query,
  QueryCalculatePossibleEjiPriceInfoArgs,
  QueryGenerateServicesArgs,
  QueryGetAllPossibleServicesArgs,
} from "../../generated/nest-graphql";
import { ServicesReceiptSection } from "./ServicesReceiptSection";
import { Field } from "formik";
import { isNil, path } from "ramda";
import { ServicesTable } from "./ServicesTable";
import { CALCULATE_POSSIBLE_EJI_PRICE_INFO } from "../../graphql/queries/calculatePossibleEJIPriceInfo";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import { ProductSelectionOption } from "./ProductRow";
import { GET_ALL_POSSIBLE_SERVICES } from "../../graphql/queries/getAllPossibleServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { pipe } from "fp-ts/lib/function";
import { useShowSuccess, useShowWarning } from "../../redux/slices/snackbar";
import { GENERATE_SERVICES } from "../../graphql/queries/generateServices";
import { generateServiceEventMessages } from "./ServiceFunctions";
import { doesNotExist } from "../../commonFunctions";

type ServicesSectionProps = {
  ejiType?: string;
  partsStores: PartsStore[];
  setValues: any;
  values: any;
  taxable: boolean;
  parts: ProductSelectionOption[];
  jobId?: string;
};

type AddServiceProps = {
  calculatePossibleEJIPriceInfo: any;
  taxable: boolean;
  ejiType?: string;
  serviceList: PossibleEjiService[];
};

type ServiceOption = {
  label: string;
  value: PossibleEjiService;
};

const ServicesSection = ({ partsStores, values, setValues, ejiType, parts, taxable, jobId }: ServicesSectionProps) => {
  const showSuccess = useShowSuccess();
  const showWarning = useShowWarning();
  const [calculatePossibleEjiPriceInfo] = useLazyQuery<Query, QueryCalculatePossibleEjiPriceInfoArgs>(
    CALCULATE_POSSIBLE_EJI_PRICE_INFO,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (priceData) => {
        if (!priceData?.calculatePossibleEJIPriceInfo) return;
        const { ejiServices, ejiPriceInfo } = priceData.calculatePossibleEJIPriceInfo;
        if (!ejiServices || !ejiPriceInfo) return;
        setValues({
          ...values,
          promoCodes: ejiPriceInfo?.promoCodes ?? [],
          services: ejiServices,
          discounts: ejiPriceInfo?.discounts ?? [],
          priceInfo: ejiPriceInfo,
        });
      },
      onError: (error) => {
        console.log("Error in calculatePossibleEjiPriceInfo:", error);
      },
    }
  );
  const [
    generateServices,
    {
      data: generateServicesData,
      // TODO: may want to add a error snackbar for this
      error: generateServicesError,
      called: generateServicesCalled,
      loading: generateServicesLoading,
    },
  ] = useLazyQuery<Query, QueryGenerateServicesArgs>(GENERATE_SERVICES, {
    // fetchPolicy: "network-only",
    // notifyOnNetworkStatusChange: true,
    onCompleted: (generateServicesData) => {
      setValues((currentValues) => {
        if (generateServicesData?.generateServices?.events?.length > 0) {
          showWarning({
            message: generateServiceEventMessages(generateServicesData?.generateServices?.events).join("\n"),
            autoHideDuration: null,
          });
        }
        return {
          ...currentValues,
          services: generateServicesData?.generateServices?.priceResult?.ejiServices ?? [],
          discounts: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo?.discounts ?? [],
          priceInfo: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo,
          serviceGenerationEvents: generateServicesData?.generateServices?.events ?? [],
        };
      });
    },
  });
  const { data: possibleServices } = useQuery<Query, QueryGetAllPossibleServicesArgs>(GET_ALL_POSSIBLE_SERVICES, {
    variables: {
      getAllPossibleServicesInput: {
        inEstimate: false,
      },
    },
  });

  const onSubmitGetDefault = useCallback(() => {
    if (!generateServicesCalled) {
      generateServices({
        variables: {
          generateServicesInput: {
            year: path(["year"], values) ?? path(["contact", "lead", "VehicleInfo", "year"], values),
            make: path(["make"], values) ?? path(["contact", "lead", "vehicleInfo", "make"], values),
            model: path(["model"], values) ?? path(["contact", "lead", "vehicleInfo", "model"], values),
            market: path(["market"], values) ?? path(["contact", "lead", "market"], values),
            requestedServices:
              path(["requestedServices"], values) ?? path(["contact", "lead", "requestedServices"], values),
            vehicleSymptoms:
              path(["vehicleSymptoms"], values) ??
              pipe(path(["contact", "lead", "vehicleSymptoms"], values), (symptoms: any[]) =>
                symptoms?.map((s) => ({
                  axle: s?.axle,
                  symptom: s?.symptom,
                }))
              ),
            jobId: jobId,
          },
        },
      });
    }
    // Get values from cache
    if (generateServicesCalled && !generateServicesLoading) {
      setValues((currentValues) => {
        if (generateServicesData?.generateServices?.events?.length > 0) {
          showWarning({
            message: generateServiceEventMessages(generateServicesData?.generateServices?.events).join("\n"),
            autoHideDuration: null,
          });
        }
        return {
          ...currentValues,
          services: generateServicesData?.generateServices?.priceResult?.ejiServices ?? [],
          discounts: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo?.discounts ?? [],
          priceInfo: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo,
          serviceGenerationEvents: generateServicesData?.generateServices?.events ?? [],
        };
      });
    }
  }, [
    jobId,
    generateServicesData?.generateServices?.priceResult?.ejiPriceInfo,
    generateServicesData?.generateServices?.priceResult?.ejiServices,
    generateServicesData?.generateServices?.events,
    generateServicesCalled,
    generateServicesLoading,
    generateServices,
    setValues,
    values,
    showWarning,
  ]);

  if (generateServicesLoading) return <FontAwesomeIcon icon={faSpinner as any} spin={true} />;
  if (!possibleServices || (!generateServicesCalled && doesNotExist(values.services))) {
    return (
      <Button onClick={onSubmitGetDefault} type={"button"}>
        + Generate Default Services
      </Button>
    );
  }
  const serviceList: PossibleEjiService[] = possibleServices.getAllPossibleServices;
  const copyEstimateDetailsToClipboard = () => {
    const { services, priceInfo } = values;
    const servicesInEstimate = services?.filter((service) => service.inEstimate);
    const servicesNotInEstimate = services?.filter((service) => !service.inEstimate);
    const recommendedServicesString =
      servicesInEstimate?.length > 0
        ? `Recommended Services: \n${servicesInEstimate
            .map((service) => `${service.name}: $${service.customerPrice}`)
            .join("\n")}`
        : "";
    const discountString =
      (priceInfo?.discounts?.length > 0 || priceInfo?.promoCodes?.length > 0) &&
      Number(priceInfo.combinedDiscountTotal) !== 0
        ? `\nDiscount: $${priceInfo?.combinedDiscountTotal}`
        : "";
    const totalString = Number(priceInfo?.amountDue) !== 0 ? `\nTotal including tax: $${priceInfo?.amountDue}` : "";
    const possibleServicesString =
      servicesNotInEstimate?.length > 0
        ? `${recommendedServicesString && "\n\n"}We could also do: \n${servicesNotInEstimate
            .map((service) => `${service.name}: $${service.customerPrice}`)
            .join("\n")}`
        : "";
    navigator.clipboard.writeText(
      `${recommendedServicesString}${discountString}${totalString}${possibleServicesString}`
    );
    showSuccess({ message: "Estimate copied to clipboard" });
  };
  return (
    <div>
      <ServicesTable
        partsStores={partsStores}
        ejiType={ejiType}
        calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
        parts={parts}
        taxable={taxable}
        serviceList={serviceList}
      />
      <div className="flex justify-between">
        <AddService
          taxable={taxable}
          calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
          ejiType={ejiType}
          serviceList={serviceList}
        />
        <Box mr={2} className="flex items-center">
          <Button onClick={copyEstimateDetailsToClipboard} type={"button"}>
            Copy Estimate Details
          </Button>
        </Box>
      </div>
      <ServicesReceiptSection
        taxable={taxable}
        receiptValues={values?.priceInfo ?? {}}
        calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
        ejiType={ejiType}
        jobId={jobId}
      />
    </div>
  );
};

const AddService = ({ calculatePossibleEJIPriceInfo, ejiType, serviceList, taxable }: AddServiceProps) => {
  const [selectedService, setSelectedService] = useState<ServiceOption | null>(null);
  const servicesToOptions = (serviceList: PossibleEjiService[]) => {
    return serviceList.map((service) => {
      return {
        label: service.name,
        value: service,
      };
    });
  };
  return (
    <div className="flex items-center my-4">
      <Autocomplete
        value={selectedService}
        onChange={(_, selectedOption: ServiceOption) => {
          setSelectedService(selectedOption);
        }}
        options={servicesToOptions(serviceList)}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Service" variant="outlined" />}
      />
      <Box ml={2}>
        <Field name={"services"}>
          {({ field: { value }, form: { values } }) => {
            const currentServices = value ?? [];
            return (
              <Button
                onClick={() => {
                  if (!isNil(selectedService)) {
                    const partsStore = values.partsOrdered ? null : values?.technician?.homePartsStore;
                    const ejiService = {
                      ...selectedService.value,
                      items: selectedService.value.items
                        .filter(({ behavior }) => behavior === "Default")
                        .map((item) => ({
                          ...item,
                          orderItem: {
                            ...item.orderItem,
                            partsStore: !values.partsOrdered ? values?.technician?.homePartsStore ?? null : null,
                          },
                        })),
                    };
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput([...currentServices, ejiService]),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          marketName: values.market,
                          taxable: taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                    setSelectedService(null);
                  }
                }}
                type={"button"}
              >
                + Add Service
              </Button>
            );
          }}
        </Field>
      </Box>
    </div>
  );
};

export default ServicesSection;
