import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { VehicleInfoFormSection } from "../VehicleInfo/VehicleInfoFormSection";
import { Appointment, PartsStore, Query } from "../../generated/nest-graphql";
import { ItemsTable, ItemsTableType } from "../Items/ItemsTable";
import { FormikErrors, useField } from "formik";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import {
  formatDateTime,
  getEndTimeWindowOffsetForMarket,
  getStartTimeWindowOffsetForMarket,
  handleNoDecimal,
  isPresent,
} from "../../lib/functions";
import {
  allPass,
  and,
  anyPass,
  append,
  equals,
  find,
  isEmpty,
  isNil,
  join,
  keys,
  omit,
  path,
  pathOr,
  pluck,
  prop,
  propEq,
  propOr,
  remove,
  startsWith,
  uniq,
} from "ramda";
import { add } from "date-fns/fp";
import { SelectField } from "../FormFields/SelectField";
import { ActionsSelect } from "../FormFields/ActionsSelect";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { ContactsSelect } from "../FormFields/ContactsSelect";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { TextField } from "../FormFields/TextField";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { AppointmentFormWithModal } from "./AppointmentFormWithModal";
import { AppointmentFormSection } from "./AppointmentFormSection";
import Box from "@material-ui/core/Box";
import { Button } from "../Buttons/Button";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { SaveCancelRow } from "../SaveCancelRow";
import { CopyJobButton } from "../Buttons/CopyJobButton";
import { NuModal } from "../NuModal";
import { ItemForm, ItemFormValues } from "./ItemForm";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { AddItemSetButton } from "../Buttons/AddItemSetButton";
import { Space } from "../Space";
import { Chip, Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { JobPartsStoreSelectField } from "../FormFields/JobPartsStoreSelectField";
import { flow, pipe } from "fp-ts/lib/function";
import { JobDetailsFormValues } from "./JobDetailsForm";
import { ItemsReceiptSection } from "../Items/ItemsReceiptSection";
import { usePartsStores } from "../../hooks/usePartsStores";
import { JOB_STATUS_MASTER_LIST, WITHDRAW_REASONS } from "../../lib/constants";
import ServicesSection from "../Services/ServicesSection";
import { partSpec } from "../specs/partSpec";
import { GET_PART_CATALOGUE } from "../../graphql/queries/getPartCatalogue";
import { exists, rejectBlank } from "../../commonFunctions";
import { InitialJobValues, JobTouchedValues, JobValues } from "../Jobs/JobFormTypes";
import { useMarkets } from "../../hooks/useMarkets";

export const JobDetailsInternalForm: React.FC<{
  values: JobValues;
  touched: JobTouchedValues;
  setValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  jobNumber?: string;
  jobId?: string;
  appointment: Appointment;
  showAddAppointment: boolean;
  toggleItemModal: () => void;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
  validateForm: (values?: any) => Promise<FormikErrors<JobDetailsFormValues>>;
  resetForm: any;
  initialValues: InitialJobValues;
  isSubmitting: boolean;
  isValid: boolean;
  itemModalIsOpen: boolean;
  hasReschedules: boolean;
}> = ({
  isValid,
  isSubmitting,
  setValues,
  setFieldValue,
  initialValues,
  jobId,
  submitForm,
  values,
  jobNumber,
  appointment,
  showAddAppointment,
  itemModalIsOpen,
  validateForm,
  resetForm,
  toggleItemModal,
  touched,
  hasReschedules,
}) => {
  const markets = useMarkets();
  const [timeZone, setTimeZone] = useState("America/Chicago");
  useEffect(() => {
    if (exists(values.market) && exists(markets)) {
      const getTimeZone = find(propEq("name", values.market))(markets);
      setTimeZone(propOr("America/Chicago", "timeZone", getTimeZone));
    }
  }, [markets, values.market]);

  const { user } = useAuth0();
  const isUserISAManager =
    user && ["matthew@nubrakes.com", "cole@nubrakes.com", "will@nubrakes.com"].includes(user.email);

  const { data, loading } = useQuery<Query>(GET_PRODUCTS);
  const { data: partsData, loading: partsLoading } = useQuery<Query>(GET_PART_CATALOGUE);
  const partsStoresData = usePartsStores();
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const { pipedriveDealId } = initialValues;
  useEffect(() => {
    const { make, model, year } = values;
    const computedJobName = join(" ", rejectBlank([year!, make!, model!]));
    if (!touched.jobName) {
      if (values.jobName !== computedJobName) {
        setFieldValue("jobName", computedJobName);
      }
    }
  }, [values.type, values.make, values.model, values.year, values, touched.jobName, setFieldValue]);
  const market = values.market;
  const partsStores = anyPass([isNil, isEmpty])(market)
    ? partsStoresData
    : partsStoresData.filter((x) => equals(path(["market", "name"], x)!, market!));
  const defaultStartTime = new Date();
  const defaultEndTime = add({ hours: 1 }, defaultStartTime);
  const onDeleteItem = (idx: number) => () => {
    const result = remove(idx, 1, values.items);
    setFieldValue("items", result, true);
  };
  const postSelectProduct = (prefix: string) => (value: ProductOptionType) => {
    const selectedValue: any = prop("value", value);
    const valueKeys = pipe(selectedValue, omit(["id"]), keys);
    valueKeys.forEach((key: any) => {
      const val = selectedValue[key];
      const keyWithPrefix = `${prefix}.${key}`;
      setFieldValue(keyWithPrefix, val ? val : "");
    });
  };
  const partsOrdered = prop("partsOrdered", values);
  const partsOrderedTimestamp = prop("partsOrderedTimestamp", values);
  const [field] = useField("appointmentInfo");
  const { value: appointmentValue } = field;
  const { status } = values;
  const parts = partsLoading ? [] : partsData.getPartCatalogue?.map(partSpec);

  return (
    <div ref={currentRef as any}>
      <div className="mb-4">{jobNumber && `Job Number: ${jobNumber}`} {hasReschedules && <Chip className="ml-2" color="secondary" label="RESCHEDULED"/>}</div>
      <div className="mb-4">
        {exists(pipedriveDealId) && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              // link to production only if in production
              href={`${
                window.location.hostname === "ops.nubrakes.com"
                  ? "https://nubrakes-1f1d7d.pipedrive.com/deal/"
                  : "https://nubrakes-sandbox.pipedrive.com/deal/"
              }${pipedriveDealId}`}
            >
              Pipedrive Deal : {pipedriveDealId}
            </a>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <SelectField name={"status"} options={uniq([status, ...JOB_STATUS_MASTER_LIST])} label={"Status"} />
        {jobId && (
          <ActionsSelect
            entityName={"Job"}
            entityId={jobId}
            hasTechnician={isPresent(path(["technician"], appointment))}
            hasServices={isPresent(path(["services"], values))}
          />
        )}
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4">
        {flow(pathOr("", ["status"]), startsWith("Withdrawn"))(values) && (
          <SelectField
            name={"withdrawReason"}
            label={"Withdraw Reason"}
            options={WITHDRAW_REASONS}
            helperText={
              prop("withdrawnAt", values) ? `Withdrawn at ${formatDateTime(prop("withdrawnAt", values))}` : undefined
            }
          />
        )}
        {and(
          startsWith("Withdrawn", pathOr("", ["status"], values)),
          startsWith("Other", pathOr("", ["withdrawReason"], values))
        ) && <TextField name={"withdrawExtraInfo"} label={"Additional Withdraw Details"} />}
      </div>
      <DetailViewContainer title={"Contact info"}>
        <div className="grid grid-cols-3 gap-4">
          <ContactsSelect name={"contact"} label={"Contact"} required={true} />
          <TextField name={"email"} label={"Contact Email"} type={"email"} helperText={"Updates contact email"} />
          <div className="row-span-3">
            <TextField name={"serviceLocationNotes"} label={"Service Location Notes"} multiline={true} rows={6} />
          </div>
          <MarketFieldSelect name={"market"} required={true} />
          <LocationSearchInput name={"serviceLocation"} label={"Service Location"} />
        </div>
      </DetailViewContainer>
      <DetailViewContainer title={"Job Details"}>
        <div className="grid grid-cols-2 gap-4">
          <SelectField
            name={"type"}
            required={true}
            options={["Brake Repair Service", "Diagnostic", "Service Call", "Warranty Check"]}
            label={"Type"}
          />
          <TextField name={"jobName"} label={"Job Name"} required={true} />
          <TextField name={"description"} label={"Description"} multiline={true} rows={4} />
          <TextField
            name={"privateNotes"}
            label={"Private Notes"}
            multiline={true}
            rows={4}
            helperText="Internal notes used by ops and technicians."
          />
          {isUserISAManager && <TextField name={"estimate"} label={"Estimate ID"} />}
          {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
            <TextField required={true} name={"jobCausedWarrantyCheck"} label={"Job Prior to Warranty Check"} />
          )}
          {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
            <SelectField
              name={"warrantyCheckReason"}
              label={"Warranty Check Reason"}
              options={[
                "Part defect",
                "Technician did not install parts correctly",
                "Technician misdiagnosed repair",
                "Customer expectations set incorrectly",
                "Abnormal customer driving behavior",
                "Other",
              ]}
            />
          )}
          {allPass([
            flow(pathOr("", ["type"]), startsWith("Warranty")),
            flow(pathOr("", ["warrantyCheckReason"]), startsWith("Other")),
          ])(values) && <TextField name={"warrantyCheckReasonExtraInfo"} label={"Additional Warranty Check Details"} />}
        </div>
      </DetailViewContainer>
      <PartsInfoSection
        appointment={appointment}
        partsOrdered={partsOrdered}
        partsOrderedTimestamp={partsOrderedTimestamp}
        setFieldValue={setFieldValue}
      />
      {showAddAppointment && (
        <DetailViewContainer title={"Appointment Info"}>
          <AppointmentFormWithModal
            appointment={appointmentValue}
            itemPartsStores={pluck("partsStore", values.items) as PartsStore[]}
            services={values?.services}
            partsOrdered={values.partsOrdered}
            jobId={jobId}
            parentValidateForm={validateForm}
            parentSetFieldValue={setFieldValue}
            market={market}
            serviceLocation={values.serviceLocation}
            timeZone={timeZone}
            contactId={values.contact?.id}
            estimateId={values.estimate}
            initialValues={{
              allDay: false,
              endDate: defaultEndTime,
              subject: values.jobName,
              startDate: defaultStartTime,
              startTimeWindow: add({ minutes: getStartTimeWindowOffsetForMarket(market) }, defaultStartTime),
              endTimeWindow: add({ minutes: getEndTimeWindowOffsetForMarket(market) }, defaultStartTime),
              technician: null,
              timeZone,
              overrideOutsideServiceZone: false,
            }}
          />
        </DetailViewContainer>
      )}
      {jobId && (
        <DetailViewContainer title={"Assign & Schedule"}>
          <AppointmentFormSection
            isValid={isValid}
            services={values?.services}
            parentValidateForm={validateForm}
            partsOrdered={values.partsOrdered!}
            itemPartsStores={pluck("partsStore", values.items) as PartsStore[]}
            jobNumber={jobNumber!}
            market={market}
            parentSubmit={submitForm}
            parentSetFieldValue={setFieldValue}
            appointment={appointment}
            jobName={values.jobName}
            jobId={jobId}
            timeZone={timeZone}
            contactId={values.contact?.id}
            estimateId={values.estimate}
            serviceLocation={values.serviceLocation}
          />
        </DetailViewContainer>
      )}
      {values?.serviceCatalogueUsed === "Services" ? (
        <DetailViewContainer title={"Services"}>
          {!market || market === "ZipUnknown" || market === "" ? (
            "Please select a market first."
          ) : (
            <ServicesSection
              taxable={values.taxable}
              partsStores={partsStores}
              values={values}
              setValues={setValues}
              parts={parts}
              jobId={jobId}
            />
          )}
        </DetailViewContainer>
      ) : (
        <DetailViewContainer title={"Items"}>
          <div className="flex flex-row justify-start mb-4">
            <Box mr={2}>
              <Button onClick={toggleItemModal} type={"button"}>
                + Add Item
              </Button>
            </Box>
            <AddItemSetButton
              name={"items"}
              defaultPartsStore={
                !values.partsOrdered
                  ? values?.appointmentInfo?.technician?.homePartsStore ?? appointment?.technician?.homePartsStore!
                  : null
              }
            />
          </div>
          {!loading && data?.getProducts && (
            <ItemsTable
              products={data.getProducts}
              items={values.items}
              onDeleteItem={onDeleteItem}
              postItemSelect={postSelectProduct}
              partsStores={partsStores}
            />
          )}
          <ItemsReceiptSection
            items={prop("items", values) as ItemFormValues[]}
            market={values.market}
            taxable={values.taxable}
          />
        </DetailViewContainer>
      )}
      <VehicleInfoFormSection defaultExpanded={false} />
      <Space />
      <SaveCancelRow
        width={width}
        offsetLeft={left}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onCancel={() => resetForm(initialValues)}
        midExtra={<>{jobId && <CopyJobButton values={values} />}</>}
      />
      <NuModal isOpen={itemModalIsOpen} title="Add Item">
        {!loading && data?.getProducts && (
          <ItemForm
            defaultPartsStore={
              !values.partsOrdered
                ? values?.appointmentInfo?.technician?.homePartsStore ?? appointment?.technician?.homePartsStore!
                : null
            }
            products={data.getProducts}
            onSubmit={async (itemFormValues: ItemFormValues) => {
              const oldItems: ItemsTableType[] = values.items;
              const { product, name, description, partNumber, partsCost, laborCost, amount, isInEstimate, partsStore } =
                itemFormValues;
              const cleanedItemFormValues: ItemFormValues = {
                amount: handleNoDecimal(amount),
                laborCost: handleNoDecimal(laborCost),
                partsCost: handleNoDecimal(partsCost),
                isInEstimate,
                partNumber,
                description,
                name,
                product,
                partsStore,
              };
              const result = append(cleanedItemFormValues, oldItems as any[]);
              setFieldValue("items", result, false);
              toggleItemModal();
            }}
            onCancel={toggleItemModal}
          />
        )}
      </NuModal>
    </div>
  );
};

const PartsInfoSection = ({
  appointment,
  partsOrdered,
  partsOrderedTimestamp,
  setFieldValue,
}: {
  appointment: Appointment;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  partsOrdered: boolean;
  partsOrderedTimestamp: Date;
}) => {
  return (
    <DetailViewContainer title={"Parts Info"}>
      <div className="grid grid-cols-3 gap-4">
        <div className="grid col-span-1 gap-2">
          <TextField name={"partsOrderNumber"} label={"Parts Order Number"} />
          <SelectField
            name={"partsLeadTimeInDays"}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            label={"Parts Lead Time in Days"}
            noneValue={0}
          />
          <CheckBoxField
            name={"partsOrdered"}
            label={"Parts Ordered"}
            postOnChange={(value: boolean) => {
              value ? setFieldValue("partsOrderedTimestamp", new Date()) : setFieldValue("partsOrderedTimestamp", null);
            }}
          />
          {partsOrdered && <Typography>{formatDateTime(partsOrderedTimestamp)}</Typography>}
        </div>
        <div className="grid col-span-1 gap-2 ">
          <LocationSearchInput name={"partsLocation"} label={"Parts Location"} />
          <TextField name={"partsNotes"} label={"Parts Notes"} multiline={true} rows={4} />
        </div>
        <div className="grid col-span-1 gap-2">
          <JobPartsStoreSelectField
            homeStore={pathOr(null, ["technician", "homePartsStore"], appointment)!}
            name={"partsStore"}
            label={"Parts Store"}
            postOnChange={(partsStore) => {
              setFieldValue("partsLocation", propOr(null, "address", partsStore));
              return true;
            }}
          />
          <SelectField
            name={"partsOrderingIssue"}
            required={false}
            options={[
              "Did not clarify vehicle details",
              "Incorrect Data - Advance",
              "Wrong parts - mis-boxed by Advance",
              "Parts availability issue",
              "Missing part(s)",
              "Parts not ordered",
              "Parts ordered to wrong store",
            ]}
            label={"Parts Ordering Issue"}
            noneValue={"none"}
          />
          <TextField name={"callForPartsTicketNumber"} label={"Call For Parts Ticket #"} />
        </div>
      </div>
    </DetailViewContainer>
  );
};
