import * as React from "react";
import { useEffect, useState } from "react";
import { TextField } from "../FormFields/TextField";
import { padLifeOptions } from "../FormFields/padLifeOptions";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectField } from "../FormFields/SelectField";
import { AutoCompleteCheckboxes } from "../FormFields/AutoCompleteCheckboxes";
import FormLabel from "@material-ui/core/FormLabel";
import { getMakesByYear, getModelsByYearAndMake, getYears } from "../../lib/quoteFormUtil";
import { useFormikContext } from "formik";
import { AutoCompleteSelectField } from "../FormFields/AutoCompleteSelectField";
import { US_STATES } from "../../lib/constants";

export const vehicleBrakeSymptomOptions = [
  "Squeaking",
  "Grinding",
  "Shaking/Vibrating",
  "Mushy/Soft Brake Pedal",
  "Stiff Brake Pedal",
  "Fluid Leak in Front or Rear",
  "Other",
];

export type VehicleInfoValues = {
  make?: string;
  model?: string;
  year?: string;
  licensePlate?: string;
  licensePlateState?: string;
  vin?: string;
  odometer?: string;
  frontPadLife?: string;
  rearPadLife?: string;
  frontBrakeSymptoms?: string[];
  rearBrakeSymptoms?: string[];
  customerExpectation?: string;
  additionalNotes?: string;
  extraInfo?: string;
  symptoms?: string;
};
export const VehicleInfoFormSection: React.FC<{ defaultExpanded?: boolean }> = ({ defaultExpanded = true }) => {
  const { setFieldValue, values } = useFormikContext<VehicleInfoValues>();
  const years = getYears();
  const [modelOptions, setModelOptions] = useState([""]);
  const [makeOptions, setMakeOptions] = useState([""]);
  useEffect(() => {
    if (values.year) {
      setMakeOptions(getMakesByYear(values.year));
    }
    if (values.year && values.make) {
      setModelOptions(getModelsByYearAndMake(values.year, values.make));
    }
  }, [values.year, values.make]);
  return (
    <DetailViewContainer title={"Vehicle Info"} defaultExpanded={defaultExpanded}>
      <div className="grid grid-cols-3 gap-4">
        <AutoCompleteSelectField
          name={"year"}
          label={"Year"}
          options={years}
          postOnChange={(year) => {
            setFieldValue("make", "");
            setFieldValue("model", "");
            if (year) {
              setMakeOptions(getMakesByYear(year));
            }
          }}
        />
        <AutoCompleteSelectField
          name={"make"}
          options={makeOptions}
          label={"Make"}
          postOnChange={(make) => {
            setFieldValue("model", "");
            if (make && values.year) {
              setModelOptions(getModelsByYearAndMake(values.year, make));
            }
          }}
        />
        <AutoCompleteSelectField name={"model"} options={modelOptions} label={"Model"} />
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <TextField name={"licensePlate"} label={"License Plate"}/>
          </div>
          <SelectField name={"licensePlateState"} options={US_STATES} label={"State"} />
        </div>
        <TextField name={"vin"} label={"VIN"} />
        <TextField name={"odometer"} label={"Odometer"} />
        <div className="row-span-2">
          <TextField name={"extraInfo"} label={"Extra Info"} multiline={true} rows={4} />
        </div>
        <div className="row-span-2">
          <TextField name={"symptoms"} label={"Symptoms"} multiline={true} rows={4} style={{ whiteSpace: "pre-line" }} />
        </div>
        <div>
          <SelectField name={"frontPadLife"} options={padLifeOptions} label={"Front Pad Life"} />
        </div>
        <div>
          <SelectField name={"rearPadLife"} options={padLifeOptions} label={"Rear Pad Life"} />
        </div>
        <div className="col-span-3">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <FormLabel component="legend">Symptoms</FormLabel>
            </div>
            <div className="px-4">
              <AutoCompleteCheckboxes
                name={"frontBrakeSymptoms"}
                label={"Front Brake Symptoms"}
                options={vehicleBrakeSymptomOptions}
              />
            </div>
            <div className="px-4">
              <AutoCompleteCheckboxes
                name={"rearBrakeSymptoms"}
                label={"Rear Brake Symptoms"}
                options={vehicleBrakeSymptomOptions}
              />
            </div>
            <div className="px-4">
              <TextField name={"additionalNotes"} label={"Additional Notes"} multiline={true} rows={4} />
            </div>
            <div className="px-4">
              <TextField name={"customerExpectation"} label={"Customer Expectation"} multiline={true} rows={4} />
            </div>
          </div>
        </div>
      </div>
    </DetailViewContainer>
  );
};
