import { useQuery } from "@apollo/client";
import { createStyles, LinearProgress, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { endOfToday, startOfToday } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { flow } from "fp-ts/lib/function";
import palette from "google-palette";
import { DateTime } from "luxon";
import {
  __,
  complement,
  contains,
  defaultTo,
  filter,
  includes,
  indexBy,
  isNil,
  map,
  not,
  path,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
} from "ramda";
import React, { useState } from "react";
import { AppointmentCalendar } from "../../components/Appointments/AppointmentCalendar";
import { AppointmentsFilterSection } from "../../components/Appointments/AppointmentsFilterSection";
import { SchedulingMarketSelect } from "../../components/FormFields/SchedulingMarketSelect";
import { Query, QueryGetAppointmentsArgs, QueryGetTechniciansArgs, Technician } from "../../generated/nest-graphql";
import { GET_APPOINTMENTS } from "../../graphql/queries/getAppointments";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { useScheduling } from "../../hooks/useScheduling";
import { useStateWithSessionStorage } from "../../hooks/useStateWithSessionStorage";
import { mapIndexed } from "../../lib/functions";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    // necessary for content to be below app bar
    content: {
      flexGrow: 1,
      display: "grid",
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  })
);

const SchedulePage: React.FC<{}> = () => {
  const classes = useStyles();
  const { calendarView, setCalendarView, includeIsActive, setIncludeIsActive, currentMarket, setCurrentMarket } =
    useScheduling();
  const [techniciansToInclude, setTechsToInclude] = useState([]);
  const setTechniciansToInclude = (technicians: string[]) => {
    sessionStorage.setItem(`${currentMarket.name}-technicians`, JSON.stringify(technicians));
    setTechsToInclude(technicians);
  };
  const timeZone: string = propOr(DateTime.utc().zoneName, "timeZone", currentMarket);
  const [dateRanges, setDateRanges] = useStateWithSessionStorage("dateRange", {
    startRange: zonedTimeToUtc(startOfToday(), timeZone).toString(),
    endRange: zonedTimeToUtc(endOfToday(), timeZone).toString(),
  });
  const onDateRangeChange = (startRange, endRange) => {
    setDateRanges({
      startRange: zonedTimeToUtc(startRange, timeZone).toString(),
      endRange: zonedTimeToUtc(endRange, timeZone).toString(),
    });
  };
  const [dateToFocusOn, setDateToFocusOn] = useStateWithSessionStorage("dateToFocus", new Date());
  const marketFilter = currentMarket.name === "All" ? {} : { filter: { "technicianCopy.market": currentMarket.name } };
  const { data, loading } = useQuery<Query, QueryGetAppointmentsArgs>(GET_APPOINTMENTS, {
    variables: {
      ...dateRanges,
      ...marketFilter,
    },
  });
  const events = map(({ technician, allDay, subject, endDate, startDate, id, job, timeZone }) => ({
    id,
    technician,
    technicianId: prop("id", technician),
    resourceTitle: `${prop("firstName", technician)} ${prop("lastName", technician)}`,
    start: new Date(startDate),
    end: new Date(endDate),
    job,
    allDay,
    title: `${subject} ${propOr("", "serviceLocation", job)}`,
    subject,
    timeZone,
  }))(defaultTo([], prop("getAppointments", data)));

  const eventsFiltered = pipe(
    filter(flow(pathOr("", ["job", "status"]), complement(contains("Withdraw")))),
    filter((event: any) => {
      const {
        technician: { id },
      } = event;
      return techniciansToInclude.includes(id);
    }),
    filter((event: any) => {
      if (isNil(includeIsActive)) return true;
      return path(["technician", "isActive"], event);
    }),
    filter((event: any) => {
      return pipe(
        path(["job", "status"]),
        // @ts-ignore
        includes(__, ["Closed", "Withdrawn", "Withdrawn: Rescheduled"]),
        not
        // @ts-ignore
      )(event);
    })
  )(events);

  const convertedEvents = map((event: any) => {
    const { start, end, ...rest } = event;
    const dateDiff = {
      start: utcToZonedTime(start, currentMarket.timeZone),
      end: utcToZonedTime(end, currentMarket.timeZone),
    };
    return {
      ...dateDiff,
      ...rest,
    };
  })(eventsFiltered);

  const techMarketFilter =
    currentMarket.name === "All" ? { filter: { isActive: true } } : { filter: { market: currentMarket.name } };
  const { data: dataTechnicians, loading: loadingTechnicians } = useQuery<Query, QueryGetTechniciansArgs>(
    GET_TECHNICIANS,
    {
      variables: techMarketFilter,
      onCompleted: (data) => {
        const stored = sessionStorage.getItem(`${currentMarket.name}-technicians`);
        const parsed = JSON.parse(stored);

        if (stored && parsed.length) {
          setTechniciansToInclude(parsed);
        } else {
          const filteredTechs = filter((technician: Technician) => {
            if (isNil(includeIsActive)) return true;
            // @ts-ignore
            return propEq("isActive", includeIsActive, technician);
          }, data.getTechnicians);
          const allTechnicians = pluck("id", filteredTechs);
          setTechniciansToInclude(allTechnicians);
        }
      },
    }
  );

  const technicians: Technician[] = defaultTo([], prop("getTechnicians", dataTechnicians));
  const filteredTechs = filter((technician: Technician) => {
    if (isNil(includeIsActive)) return true;
    // @ts-ignore
    return propEq("isActive", includeIsActive, technician);
  }, technicians);

  const colors = palette("mpn65", technicians.length);
  const technicianColors = pipe(
    mapIndexed((technician, index) => {
      const color = colors[index];
      // @ts-ignore
      return { ...technician, color };
    }),
    indexBy(prop("id") as any) as any
    // @ts-ignore
  )(technicians);

  const techniciansToShowInCalendar = pipe(
    filter((technician) => {
      // @ts-ignore
      return techniciansToInclude.includes(prop("id", technician));
    })
  )(filteredTechs);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <SchedulingMarketSelect value={currentMarket.name} setValue={setCurrentMarket} />
        <div style={{ height: 10 }}>{(loading || loadingTechnicians) && <LinearProgress />}</div>
        <AppointmentCalendar
          timeZone={currentMarket.timeZone}
          dateToFocusOn={dateToFocusOn}
          onDateChange={(val) => {
            setDateToFocusOn(val);
          }}
          onDateRangeChange={onDateRangeChange}
          startRange={dateRanges.startRange}
          endRange={dateRanges.endRange}
          technicians={techniciansToShowInCalendar}
          calendarView={calendarView}
          setCalendarView={setCalendarView}
          events={convertedEvents}
          technicianColors={technicianColors}
          marketFilter={marketFilter}
        />
      </main>
      <AppointmentsFilterSection
        initialTechnicians={techniciansToInclude}
        includeIsActive={includeIsActive}
        allTechnicians={filteredTechs}
        setIncludeIsActive={setIncludeIsActive}
        setTechniciansToInclude={setTechniciansToInclude}
      />
    </div>
  );
};

export default SchedulePage;
