import { Form, Formik } from "formik";
import { defaultTo, startsWith } from "ramda";
import * as React from "react";
import { useState } from "react";
import Modal from "react-modal";
import * as Yup from "yup";
import {
  Appointment,
  Contact,
  EjiDiscount,
  EjiPriceInfo,
  EjiService,
  PartsStore,
  PossibleEjiService,
  PromoCode,
  Technician,
} from "../../generated/nest-graphql";
import { ItemsTableType } from "../Items/ItemsTable";
import { VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { AppointmentFormValues } from "./AppointmentForm";
import { JobDetailsInternalForm } from "./JobDetailsInternalForm";
import { flow } from "fp-ts/lib/function";
import { servicesSchema } from "../../yupSchemas";
import { VehicleSymptomFormValues } from "./ContactDetailsForm";
import { Button } from "../Buttons/Button";
import { NuModal } from "../NuModal";
import { servicesToPossibleEjiServiceSpec } from "../specs/servicesSpec";
import { SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { useToggle } from "../../hooks/useToggle";
import { exists } from "../../commonFunctions";

export const jobDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("Required"),
  contact: Yup.object().required("Required"),
  // serviceLocation: Yup.string().required("Required"),
  market: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  // jobName: Yup.string().required("Required"),
  withdrawReason: Yup.string().when("status", {
    is: startsWith("Withdraw"),
    then: Yup.string().required("Must Provide a Withdraw Reason"),
    otherwise: Yup.string().nullable(),
  }),
  withdrawExtraInfo: Yup.string().when("withdrawReason", {
    is: flow(defaultTo(""), startsWith("Other")),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  jobCausedWarrantyCheck: Yup.string().when("type", {
    is: (value) => value === "Warranty Check",
    then: Yup.string()
      .length(24)
      .required(
        "Must Provide a JobID of the job that caused the warranty, the job ID is the url of the job in the format ops.nubrakes.com/jobs/<jobid>"
      ),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckReason: Yup.string().when("type", {
    is: flow(defaultTo(""), startsWith("Warranty")),
    then: Yup.string().required("Must Provide a Warranty Reason"),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckReasonExtraInfo: Yup.string().when("warrantyCheckReason", {
    is: flow(defaultTo(""), startsWith("Other")),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  services: servicesSchema,
  vin: Yup.string().length(17, "VIN must be 17 digits").nullable(),
});

export type JobDetailsFormValues = VehicleInfoValues & {
  id?: string;
  technician?: Technician;
  contact: Contact;
  taxable: boolean;
  email?: string;
  estimate?: string;
  serviceLocation?: string;
  status: string;
  withdrawReason?: string;
  withdrawExtraInfo?: string;
  withdrawnAt?: Date;
  type: string;
  market: string;
  appointmentId: string;
  jobName: string;
  privateNotes?: string;
  serviceLocationNotes?: string;
  description?: string;
  items: ItemsTableType[];
  partsOrderNumber?: string;
  partsNotes?: string;
  partsOrderingIssue?: string;
  partsLocation?: string;
  partsStore?: PartsStore;
  partsOrdered?: boolean;
  partsOrderedTimestamp?: Date;
  appointmentInfo?: AppointmentFormValues;
  jobCausedWarrantyCheck?: string;
  warrantyCheckReason?: string;
  warrantyCheckReasonExtraInfo?: string;
  rescheduleReason?: string;
  rescheduleFiledBy?: string;
  serviceCatalogueUsed?: string;
  services?: PossibleEjiService[] | EjiService[];
  discounts?: EjiDiscount[];
  promoCodes?: PromoCode[];
  priceInfo?: EjiPriceInfo;
  partsLeadTimeInDays?: number;
  callForPartsTicketNumber?: string;
  vehicleSymptoms?: VehicleSymptomFormValues[];
  requestedServices?: string[];
  pipedriveDealId?: string;
};

export const JobDetailsForm: React.FC<{
  initialValues: JobDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  jobId?: string;
  appointment: Appointment;
  showAddAppointment?: boolean;
  jobNumber?: string;
  hasReschedules?: boolean;
}> = ({ initialValues, onSubmit, jobId, jobNumber, appointment, showAddAppointment = false, hasReschedules = false }) => {
  const [errorModalIsOpen, , toggleErrorModal] = useToggle();
  const [errorMessage, setErrorMessage] = useState("");
  Modal.setAppElement("#root");
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const toggleItemModal = () => {
    setItemModalIsOpen(!itemModalIsOpen);
  };
  const wrappedOnSubmit = async (values: JobDetailsFormValues, formikHelpers) => {
    if (exists(values.services)) {
      // Custom validation needs to be done here (instead of using Formik and Yup)
      // since there is no nice way to communicate these error conditions to the
      // user prior to clicking the save button.
      const valuesWithPossibleEJIs = { ...values, services: servicesToPossibleEjiServiceSpec(values.services) };
      const isServiceCatalogueUsed = valuesWithPossibleEJIs.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES;
      const isNoServiceIncludedInEstimate =
        valuesWithPossibleEJIs.services.filter((service) => service.inEstimate).length === 0;
      // const isCustomerPriceZero = currency(valuesWithPossibleEJIs.priceInfo?.amountDue ?? "0.00").intValue === 0;
      if (isServiceCatalogueUsed && isNoServiceIncludedInEstimate) {
        setErrorMessage("At least one service must be included in the estimate to generate a quote.");
        toggleErrorModal();
        return;
      }
    }

    await onSubmit(values, formikHelpers);
  };

  return (
    <Formik<JobDetailsFormValues>
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        return wrappedOnSubmit(values, helpers);
      }}
      enableReinitialize={true}
      validationSchema={jobDetailsFormValidationSchema}
      validateOnMount
    >
      {({ isSubmitting, submitForm, setValues, isValid, resetForm, values, setFieldValue, touched, validateForm }) => {
        return (
          <Form>
            <JobDetailsInternalForm
              setValues={setValues}
              values={values as any}
              touched={touched}
              setFieldValue={setFieldValue}
              jobNumber={jobNumber}
              jobId={jobId}
              appointment={appointment}
              showAddAppointment={showAddAppointment}
              toggleItemModal={toggleItemModal}
              submitForm={submitForm}
              resetForm={resetForm}
              initialValues={initialValues}
              isSubmitting={isSubmitting}
              validateForm={validateForm}
              isValid={isValid}
              itemModalIsOpen={itemModalIsOpen}
              hasReschedules={hasReschedules}
            />
            <NuModal isOpen={errorModalIsOpen} title="No Services Included in Estimate">
              <div>
                <p>{errorMessage}</p>
                <Button className="mt-4 float-right" type={"button"} onClick={toggleErrorModal}>
                  OK
                </Button>
              </div>
            </NuModal>
          </Form>
        );
      }}
    </Formik>
  );
};
