import { Column } from "react-table";
import { PartsStore, Query } from "../../generated/nest-graphql";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PARTS_STORES } from "../../graphql/queries/getPartsStores";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { path } from "ramda";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { filterDropDownStyles } from "../../material-ui-styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { AddToolbarButton } from "../Table/AddToolbarButton";
import { doesNotExist } from "../../commonFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

const PartsStoreColumns: Column<PartsStore>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/parts-stores/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  {
    Header: "Market",
    accessor: "market",
    Cell: (props) => {
      return <>{path(["cell", "value", "name"], props)}</>;
    },
  },
  { Header: "Store No.", accessor: "storeNumber" },
  { Header: "Store Name", accessor: "name" },
  { Header: "Address", accessor: "address" },
];

export const PartsStoresTable = ({ options }: { options: any }) => {
  const { data, loading } = useQuery<Query>(GET_PARTS_STORES);
  const classes = filterDropDownStyles();

  const [marketValue, setValue] = useState("");
  const [getPartsStores, setGetPartsStores] = useState([]);

  const onChange = (e) => {
    const { value } = e.target;
    const getParts = data.getPartsStores.filter((store) => store?.market?.name === value);
    setValue(value);
    setGetPartsStores(getParts);
  };

  useEffect(() => {
    if (!loading && doesNotExist(getPartsStores)) {
      setGetPartsStores(data.getPartsStores);
    }
  }, [loading, setGetPartsStores, data, getPartsStores]);

  return loading ? (
    <FontAwesomeIcon icon={faSpinner as any} spin={true} />
  ) : (
    <>
      <Box p={2} m={"auto"} display="flex" flexDirection="row">
        <div className={"grid grid-cols-4 gap-4"}>
          <Box style={{ width: 300 }}>
            <FormControl className={classes.root} variant="filled">
              <InputLabel>Market Filter</InputLabel>
              <Select label="Market" value={marketValue} onChange={onChange}>
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                {options.map((val, i) => (
                  <MenuItem key={i} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </Box>
      <SelectableEntityTable
        data={getPartsStores}
        columns={PartsStoreColumns}
        numRecords={getPartsStores.length}
        title={"Parts Stores"}
        endAdornments={<AddToolbarButton link={"/add/parts-store"} title={"Parts Store"} />}
      />
    </>
  );
};
