import * as React from "react";
import { useMutation } from "@apollo/client";
import { Mutation, MutationOpsAdminCreateInquiryRequestArgs } from "../../generated/nest-graphql";
import { keys} from "ramda";
import { cleanObject } from "../../lib/functions";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { Form, Formik } from "formik";
import { CreateInquiryRequestForm } from "../Forms/CreateInquiryRequestForm";
import { flow } from "fp-ts/lib/function";
import { OPS_ADMIN_CREATE_INQUIRY_REQUEST } from "../../graphql/mutations/opsAdminCreateInquiryRequest";
import { inquiryRequestSpec } from "../InquiryRequest/spec";
import { ContactDetailsFormValues, ContactDetailValidationSchema } from "../Forms/ContactDetailsForm";

export const CreateInquiryRequest: React.FC<{
  initialValues?: ContactDetailsFormValues;
}> = ({ initialValues }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();

  const [opsAdminCreateInquiryRequest] = useMutation<Mutation, MutationOpsAdminCreateInquiryRequestArgs>(
    OPS_ADMIN_CREATE_INQUIRY_REQUEST
  );

  const opsAdminCreateInquiryRequestOnSubmit = async (values) => {
    const updates = flow(inquiryRequestSpec, cleanObject)(values);
    if (keys(updates).length) {
      const { data } = await opsAdminCreateInquiryRequest({
        variables: {
          createInquiryRequestInput: updates,
        },
      });
      const id = data.opsAdminCreateInquiryRequest?.serviceRequest?.id;
      showSuccess({ message: "Successfully Created Inquiry Request" });
      await history.push(`/jobs/${id}`);
    }
  };

  return (
    <Formik<ContactDetailsFormValues>
      initialValues={
        initialValues ?? {
                zip: "",
                phoneNumber: "",
                market: "",
                make: "",
                outreachMedium: "Inbound Call",
                hearAboutUs: "",
                lastName: "",
                firstName: "",
                email: "",
                address: "",
                extraInfo: "",
                model: "",
                source: "",
                symptoms: "",
                year: "",
                additionalNotes: "",
                customerExpectation: "",
                timeZone: "",
                fleetName: "",
                isFleet: false,
                taxExemptEin: "",
                isTaxExempt: false,
                frontBrakeSymptoms: [],
                rearBrakeSymptoms: [],
                vehicleSymptoms: [],
                requestedServices: [],
            }
      }
      onSubmit={opsAdminCreateInquiryRequestOnSubmit}
      enableReinitialize={true}
      validationSchema={ContactDetailValidationSchema}
      validateOnMount
    >
      {({ isSubmitting, isValid, resetForm, values, setFieldValue }) => {
        return (
          <Form>
            <CreateInquiryRequestForm
              values={values as any}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              initialValues={initialValues}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
