import * as React from "react";
import { Button } from "@material-ui/core";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateJobArgs } from "../../generated/nest-graphql";
import { CREATE_JOB } from "../../graphql/mutations/createJob";
import { assoc, omit, pipe } from "ramda";
import { jobDetailsSpec } from "../specs/jobDetailsSpec";
import { cleanObject } from "../../lib/functions";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { INITIAL_JOB_STATUS } from "../../lib/constants";

export const CopyJobButton: React.FC<{
  values: JobDetailsFormValues;
  disabled?: boolean;
}> = ({ values, disabled = false }) => {
  const showSuccess = useShowSuccess();
  const history = useHistory();
  const [createJob, { loading }] = useMutation<Mutation, MutationCreateJobArgs>(CREATE_JOB);
  const onClick = async () => {
    const input = pipe(
      jobDetailsSpec,
      cleanObject,
      omit(["appointment", "appointmentInfo"]), // avoid copying appointment
      omit(["partsInfo"]), // avoid copying partsInfo
      omit(["promoCodes", "discounts"]), // DO NOT COPY JOB PROMOS TO COPIED JOB
      assoc("status", INITIAL_JOB_STATUS)
    )(values);
    const result = await createJob({
      variables: {
        // @ts-ignore
        createJobInput: input,
      },
    });
    const id = result?.data?.createJob?.id;
    showSuccess({ message: "Successfully Copied Job" });
    history.push(`/jobs/${id}`);
    window.scrollTo(0, 0);
  };
  return (
    <Button disabled={disabled} variant="contained" size="large" color="secondary" onClick={onClick}>
      {loading ? <FontAwesomeIcon icon={faSpinner as any} spin={true} /> : "Copy"}
    </Button>
  );
};
