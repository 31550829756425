import { DATE_TIME_FORMAT, TIME_FORMAT } from "./constants";
import {
  __,
  addIndex,
  applySpec,
  chain,
  defaultTo,
  difference,
  divide,
  either,
  filter,
  flatten,
  fromPairs,
  identity,
  isEmpty,
  isNil,
  map,
  multiply,
  not,
  path,
  pipe,
  pluck,
  prop,
  propEq,
  reject,
  sort,
  split,
  subtract,
  sum,
  toPairs,
  type,
  useWith,
  propOr,
} from "ramda";
import { DateTime } from "luxon";
import { flow } from "fp-ts/lib/function";
import currency from "currency.js";
import { EjiItem } from "../generated/nest-graphql";

export const toNumber = (val: any) => {
  return Number(val);
};

export const objectDiff = (newObj, oldObj) => {
  return fromPairs<any>(difference(toPairs(newObj), toPairs(oldObj)) as any);
};

export const sortActivityByCreatedAt = (activities: any[]) =>
  sort((activity1: any, activity2: any) => {
    return new Date(activity2.createdAt).getTime() - new Date(activity1.createdAt).getTime();
  }, activities);

// @ts-ignore
// eslint-disable-next-line react-hooks/rules-of-hooks
export const dotPath = useWith(path, [split(".")]);

export const valToString = (val) => val.toString();
export const flattenObj = (obj: any) => {
  const go = (obj_) =>
    chain(([k, v]: any[]) => {
      if (type(v) === "Object") {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
      } else {
        return [[k, v]];
      }
    }, toPairs(obj_) as any);
  return fromPairs(go(obj));
};
export const unflatten = (obj) => {
  const result = {};
  for (const objectKey in obj) {
    const keys = objectKey.split(".");
    keys.reduce(function (r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? obj[objectKey] : {}) : []);
    }, result);
  }
  return result;
};
export function cleanObjectWithoutFlattening(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || obj[propName] === {}) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object" && obj[propName] !== {}) {
      // Recurse here if the property is another object.
      cleanObjectWithoutFlattening(obj[propName]);
    }
  }
  return obj;
}

export const cleanObject = (obj) => {
  return pipe(flattenObj, reject(isNil), unflatten)(obj);
};

export const calculateSubtotalFromValues = (values: any) =>
  flow<any, any, any, any, any, any, any>(
    prop("items"),
    pluck("amount"),
    map(toNumber),
    map(multiply(100)),
    sum,
    divide(__, 100)
  )(values);

export const calculateSubtotalFromItems = (items: any[]) =>
  flow<any, any, any, any, any, any>(pluck("amount"), map(toNumber), map(multiply(100)), sum, divide(__, 100))(items);

export const calculatePartsCostFromItems = (items: any[]) =>
  flow<any, any, any, any, any, any>(
    pluck("partsCost"),
    map(toNumber),
    map(multiply(100)),
    sum,
    divide(__, 100)
  )(items);

export const calculatePartsTotalFromServices = flow<any, any, any, any, any, any, any, any, any>(
  pluck("items"),
  flatten,
  filter(propEq("type", "Part")),
  pluck("customerPrice"),
  filter((val) => not(isNil(val))),
  map(toNumber),
  sum,
  currency
);

export const calculatePriceFromProducts = pipe<any, any, any, any, any>(
  map((product: EjiItem) => multiply(product?.units, product?.vendorUnitCost)),
  sum,
  currency,
  prop("value")
);

export const calculateLaborTotalFromServices = flow<any, any, any, any, any, any, any, any, any>(
  pluck("items"),
  flatten,
  filter(propEq("type", "Labor")),
  pluck("customerPrice"),
  filter((val) => not(isNil(val))),
  map(toNumber),
  sum,
  currency
);

export const calculateFeeTotalFromServices = flow<any, any, any, any, any, any, any, any, any>(
  pluck("items"),
  flatten,
  //@ts-ignore
  filter(propEq("type", "Fee")),
  pluck("customerPrice") as any,
  filter((val) => not(isNil(val))),
  map(toNumber),
  sum,
  currency
);

export const currencyTotalFromObjectPath = (stringPath: string[], obj: any[]) =>
  flow(map(path(stringPath)), sum, defaultTo(0), currency)(obj);

export const acquireJobItemsTotal = (values: any, tax: number = 0) =>
  pipe(calculateSubtotalFromValues, (subTotal: number) => pipe(multiply(tax), subtract(subTotal))(subTotal))(values);

export const calculateSubtotalDiff = (initialValues: any, values: any, tax: number = 0) =>
  subtract(acquireJobItemsTotal(values, tax), acquireJobItemsTotal(initialValues, tax));

export const handleNoDecimal = (val: string) => {
  return val.includes(".") ? val : val + ".00";
};

export const stringToDate = (val: string) => {
  return new Date(val);
};

export const toReactSelectOptions = (options: string[] | number[] | { label: string; value: string | number }[]) =>
  map(
    applySpec({
      value: (val) => propOr(val, "value", val),
      label: (val) => propOr(val, "label", val),
    })
  )(options);

export const mapIndexed = addIndex(map);

export const formatDateTime = (dtString: any) => {
  if (!dtString) {
    return;
  }
  return DateTime.fromJSDate(new Date(dtString)).toFormat(DATE_TIME_FORMAT);
};

export const formatDateTimeFull = (dtString: any) => {
  if (!dtString) {
    return;
  }
  const options = {
    weekday: "short",
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "shortGeneric",
  };
  return DateTime.fromJSDate(new Date(dtString)).toLocaleString(options);
};

export const formatToTime = (dtString: any) => {
  if (!dtString) {
    return;
  }
  return DateTime.fromJSDate(new Date(dtString)).toFormat(TIME_FORMAT);
};

export const formatMetersToMiles = (miles: number) => {
  return `${Math.round(miles * 0.000621371192 * 10) / 10} miles`;
};

export const isValidDate = (date: any) => {
  return date instanceof Date && !isNaN(date as any);
};

export const secondsToTime = (seconds: number) => {
  const hour = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const hourDisplay = hour > 0 ? hour + (hour === 1 ? " hour " : " hours ") : "";
  const minuteDisplay = minutes > 0 ? minutes + (minutes === 1 ? " minute " : " minutes") : "";
  return hourDisplay + minuteDisplay;
};

export const isPresent = (val: any) => {
  return !either(isNil, isEmpty)(val);
};

export const cleanCurrency = (currency: string) => {
  return pipe(defaultTo("0.00"), (currency: string) => (currency.includes(".") ? currency : currency + ".00"))(
    currency
  );
};

export const isStripeReady = (values: any) => {
  return !!prop("stripeCustomer", values) && !isEmpty(prop("stripePaymentMethods", values));
};

export const tryJSONStringify = (obj: any) => {
  try {
    // JSON.stringify throws if there are cycles.
    return JSON.stringify(obj);
  } catch (_) {
    return obj;
  }
};

export const getStartTimeWindowOffsetForMarket = (market?: string) => {
  // Adjust the arrival window for the test that ops is running San Antonio.
  // enabled for all markets
  return -30;
};

export const getEndTimeWindowOffsetForMarket = (market?: string) => {
  // Adjust the arrival window for the test that ops is running San Antonio.
  // enabled for all markets
  return 90;
};
