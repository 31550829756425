import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetContactArgs, QueryGetJobsArgs } from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { isEmpty, mergeDeepRight, path, pathOr, prop } from "ramda";
import { Button } from "../Buttons/Button";
import { JobColumns, jobsToJobColumns } from "../Jobs/JobsTable";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_JOBS } from "../../graphql/queries/getJobs";
import { ROW_LIMIT } from "../../lib/constants";
import { getSymptoms, vehicleSymptomToVehicleSymptomFormValue } from "./contactDetailsFormHelpers";
import { CreateInquiryRequest } from "../Jobs/CreateInquiryRequest";
import { useMarkets } from "../../hooks/useMarkets";
import { getFieldValueFromMarkets } from "../FormFields/MarketFieldSelect";
import { exists } from "../../commonFunctions";

export const ContactsJobs: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [enableAddJob, setEnableAddJob] = useState(false);
  const { data, fetchMore } = useQuery<Query, QueryGetJobsArgs>(GET_JOBS, {
    variables: {
      limit: ROW_LIMIT,
      filter: {
        contact: contactId,
      },
    },
  });
  const contactResult = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const markets = useMarkets();
  if (!data || !path(["data", "getContact"], contactResult)) return null;
  const contactData = contactResult.data.getContact;
  const loadMore = async (cursor) => {
    await fetchMore({
      variables: {
        filter: {
          contact: contactId,
        },
        limit: ROW_LIMIT,
        cursor,
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getJobs: {
            edges: [...prev.getJobs.edges, ...fetchMoreResult.getJobs.edges],
            pageInfo: mergeDeepRight(prev.getJobs.pageInfo, fetchMoreResult.getJobs.pageInfo),
            __typename: prev.getJobs.__typename,
          },
        };
      },
    });
  };
  const market = pathOr(null, ["lead", "market"], contactData);
  return (
    <div className={"px-4"}>
      {enableAddJob ? (
        <CreateInquiryRequest
          initialValues={{
            firstName: contactData.firstName,
            lastName: contactData.lastName,
            phoneNumber: contactData.phoneNumber,
            zip: contactData.lead.zip,
            source: contactData.lead.source,
            hearAboutUs: contactData.lead.hearAboutUs,
            outreachMedium: contactData.outreachMedium,
            email: pathOr("", ["email"], contactData),
            market,
            timeZone:
              market !== null ? prop(market, getFieldValueFromMarkets(markets, "timeZone", market))?.toString() : "",
            rearPadLife: pathOr(null, ["lead", "vehicleInfo", "rearPadLife"], contactData),
            odometer: pathOr(null, ["lead", "vehicleInfo", "odometer"], contactData),
            licensePlate: pathOr(null, ["lead", "vehicleInfo", "licensePlate"], contactData),
            licensePlateState: pathOr(null, ["lead", "vehicleInfo", "licensePlateState"], contactData),
            frontPadLife: pathOr(null, ["lead", "vehicleInfo", "frontPadLife"], contactData),
            symptoms: isEmpty(getSymptoms(contactData)) ? null : getSymptoms(contactData),
            vin: pathOr(null, ["lead", "vehicleInfo", "vin"], contactData),
            year: pathOr(null, ["lead", "vehicleInfo", "year"], contactData),
            extraInfo: pathOr(null, ["lead", "vehicleInfo", "extraInfo"], contactData),
            make: pathOr(null, ["lead", "vehicleInfo", "make"], contactData),
            model: pathOr(null, ["lead", "vehicleInfo", "model"], contactData),
            address: pathOr("", ["lead", "address"], contactData),
            vehicleSymptoms: contactData?.lead?.vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? null,
            requestedServices: contactData?.lead?.requestedServices ?? null,
            fleetName: contactData?.fleet?.name ?? "",
            isFleet: exists(contactData?.fleet?.name),
            taxExemptEin: contactData?.taxExemptEin ?? "",
            isTaxExempt: exists(contactData?.taxExemptEin),
          }}
        />
      ) : (
        <>
          <div className="flex flex-row justify-end">
            <div className="my-4 mr-0">
              <Button onClick={() => setEnableAddJob(true)}>Create Inquiry Request</Button>
            </div>
          </div>
          <SelectablePageableEntityTable
            title={"Jobs"}
            queryResult={data}
            columns={JobColumns}
            queryKey={"getJobs"}
            numRecords={data.getJobs.pageInfo.numRecords}
            limit={ROW_LIMIT}
            spec={jobsToJobColumns}
            loadMore={() => loadMore(data.getJobs.pageInfo.endCursor)}
          />
        </>
      )}
    </div>
  );
};
