import React, { useMemo } from "react";
import { useField } from "formik";
import { path, pipe } from "ramda";
import { toReactSelectOptions } from "../../lib/functions";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import { ErrorTextField } from "./ErrorTextField";

export const SelectStyles = {
  menu: (base) => ({
    ...base,
    backgroundColor: "#202020",
  }),
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  option: (base, { isSelected, isFocused }) => {
    return {
      ...base,
      backgroundColor: isSelected ? "#f04e23" : isFocused ? "blue" : "#202020",
    };
  },
  placeholder: (base) => ({
    ...base,
    color: "#EDF2F7",
  }),
  control: (base) => ({
    ...base,
    minHeight: 32,
    backgroundColor: "#1a202c",
    borderColor: "#1a202c",
  }),
};

export const SelectField: React.FC<{
  name: string;
  required?: boolean;
  options: string[] | number[] | { label: string; value: string | number }[];
  label: string;
  helperText?: string | JSX.Element;
  postOnChange?: (val: any) => void;
  hideNone?: boolean;
  noneValue?: any;
}> = ({ label, options, required, name, postOnChange, helperText, hideNone, noneValue = "" }) => {
  const [field, meta, helper] = useField(name);
  const reactSelectOptions = useMemo(() => {
    return pipe(
      // @ts-ignore
      toReactSelectOptions
    )(options);
  }, [options]);
  return (
    <FormControl fullWidth variant="outlined" required={required} error={!!meta.error}>
      <InputLabel> {label} </InputLabel>
      <Select
        label={label}
        name={name}
        {...field}
        onChange={(event) => {
          // @ts-ignore
          const value = path<string>(["target", "value"], event);
          helper.setValue(value);
          postOnChange && postOnChange(value);
        }}
        value={field.value || field.value === 0 ? field.value : ""}
      >
        {!hideNone && (
          <MenuItem value={noneValue}>
            <em>None</em>
          </MenuItem>
        )}
        {reactSelectOptions.map((val: any, i) => (
          <MenuItem key={i} value={val.value}>
            {val.label}
          </MenuItem>
        ))}
      </Select>
      {meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
