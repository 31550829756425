import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationUpdateMarketArgs, Query, QueryGetMarketArgs } from "../../generated/nest-graphql";
import { GET_MARKET } from "../../graphql/queries/getMarket";
import { UPDATE_MARKET } from "../../graphql/mutations/updateMarket";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { cleanObject, objectDiff } from "../../lib/functions";
import { marketSpec } from "../specs/marketsSpec";
import { keys, path, pathOr } from "ramda";
import { MarketForm, MarketFormValues } from "../Forms/MarketForm";
import { flow } from "fp-ts/lib/function";
import React from "react";

export const MarketDetails = ({ marketId }: { marketId: string }) => {
  const { data } = useQuery<Query, QueryGetMarketArgs>(GET_MARKET, {
    variables: {
      id: marketId,
    },
  });
  const [updateMarket] = useMutation<Mutation, MutationUpdateMarketArgs>(UPDATE_MARKET);
  const showSuccess = useShowSuccess();
  if (!data) return null;

  const initialValues: MarketFormValues = {
    name: path(["getMarket", "name"], data),
    timeZone: path(["getMarket", "timeZone"], data),
    phoneNumber: path(["getMarket", "phoneNumber"], data),
    email: path(["getMarket", "email"], data),
    address: path(["getMarket", "address"], data),
    businessID: path(["getMarket", "businessID"], data),
    activeServiceAreaGeoJson: JSON.stringify(pathOr(undefined, ["getMarket", "activeServiceAreaGeoJson"], data)),
    marketAreaGeoJson: JSON.stringify(pathOr(undefined, ["getMarket", "marketAreaGeoJson"], data)),
    salesTaxType: path(["getMarket", "salesTaxType"], data),
    partsTaxRate: path(["getMarket", "partsTaxRate"], data),
    laborTaxRate: path(["getMarket", "laborTaxRate"], data),
  };

  const onSubmit = async (values: MarketFormValues) => {
    const diff = objectDiff(values, initialValues);
    // @ts-ignore
    const updates = flow(marketSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateMarket({
        variables: {
          id: marketId,
          // @ts-ignore
          updateMarketInput: updates,
        },
      });
      showSuccess({ message: "Successfully Updated Market" });
    }
  };

  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <h3>Update Market</h3>
        <MarketForm onSubmit={onSubmit} initialValues={initialValues} />
      </div>
    </div>
  );
};
