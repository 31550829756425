import * as React from "react";
import { PreJobCheckList } from "../../generated/nest-graphql";
import { List, Paper, Typography, ListItem, ListItemText } from "@material-ui/core";
import { cond, includes, T, __ } from "ramda";

export const JobChecklist: React.FC<{ preJobCheckList: PreJobCheckList }> = ({ preJobCheckList }) => {
  const itemNames = preJobCheckList
    ? Object.keys(preJobCheckList).filter((item) => preJobCheckList[item] && item !== "__typename")
    : [];
  return (
    <>
      <Paper className="mt-2 mb-3" elevation={3} square>
        <List dense>
          {itemNames.length === 0 ? (
            <EmptyList />
          ) : (
            itemNames.map((itemName, index) => {
              return cond([
                [
                  includes(__, ["tireTread"]),
                  () => (
                    <PreJobCheckListItemDisplay
                      label={itemName}
                      value={`(FrontLeft, FrontRight, RearLeft, RearRight) = (${
                        preJobCheckList[itemName].frontLeft?.depth || "unavailable"
                      }, ${preJobCheckList[itemName].frontRight?.depth || "unavailable"}, ${
                        preJobCheckList[itemName].rearLeft?.depth || "unavailable"
                      }, ${preJobCheckList[itemName].rearRight?.depth || "unavailable"}) /32"`}
                      index={index}
                    ></PreJobCheckListItemDisplay>
                  ),
                ],
                [
                  includes(__, ["oilLife"]),
                  () => (
                    <PreJobCheckListItemDisplay
                      label={itemName}
                      value={`Next Oil Change Mileage: ${
                        preJobCheckList[itemName].nextOilChangeMileage.mileage || "unavailable"
                      }`}
                      index={index}
                    ></PreJobCheckListItemDisplay>
                  ),
                ],
                [
                  includes(__, ["frontPadsV2", "rearPadsV2"]),
                  () => (
                    <PreJobCheckListItemDisplay
                      label={itemName}
                      value={
                        !preJobCheckList[itemName]?.condition
                          ? ""
                          : `${preJobCheckList[itemName]?.condition} (${
                              preJobCheckList[itemName]?.padThickness?.thickness || "unavailable"
                            } mm)`
                      }
                      index={index}
                    ></PreJobCheckListItemDisplay>
                  ),
                ],
                [
                  T,
                  () => (
                    <PreJobCheckListItemDisplay
                      label={itemName}
                      value={preJobCheckList[itemName].partCondition}
                      index={index}
                    ></PreJobCheckListItemDisplay>
                  ),
                ],
              ])(itemName);
            })
          )}
        </List>
      </Paper>
    </>
  );
};

const EmptyList = () => (
  <div className="flex content-center justify-center p-4">
    <Typography variant="body2">No Checklist Available</Typography>
  </div>
);

const PreJobCheckListItemDisplay: React.FC<{ label: any; value: any; index: number }> = ({ label, value, index }) => {
  return (
    <ListItem button key={index}>
      <ListItemText
        primary={
          <Typography className={"block"} component={"div"} style={{ textTransform: "capitalize" }}>
            {label}
          </Typography>
        }
      />{" "}
      {value}
    </ListItem>
  );
};
