import React, { useCallback, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { debounce } from "lodash";
import { Field } from "formik";

interface NumberFormatCustomProps {
  // @ts-ignore
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  const cleanValRef = useRef<any>();
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        cleanValRef.current = values;
      }}
      onChange={(e) => onChange({ target: { name: props.name, value: cleanValRef.current?.value } })}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
}

const DebouncedCurrencyInputV2: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  value: string;
  error: any;
  onChange: any;
  endAdornment?: any;
  disabled?: boolean;
}> = ({ error, name, label, value, onChange, required = false, endAdornment, disabled = false }) => {
  const handler = useCallback(debounce(onChange, 500), []);
  const [textVal, setTextVal] = useState(value);

  useEffect(() => {
    //Value displayed must be empty string rather than null
    setTextVal(value === null ? "" : value);
  }, [value]);
  return (
    <Field name={name}>
      {({ form: { values } }) => (
        <TextField
          fullWidth
          error={!!error}
          variant={"outlined"}
          name={name}
          label={label}
          onChange={(e) => {
            //We want form value to be null and displayed value to be empty string
            const value = e.target.value === "" ? null : e.target.value;
            setTextVal(e.target.value);
            handler(value, values);
          }}
          required={required}
          helperText={<ErrorTextField fieldName={name} displayError={!!error} />}
          value={textVal}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            endAdornment: endAdornment,
          }}
          disabled={disabled}
        />
      )}
    </Field>
  );
};

export default DebouncedCurrencyInputV2;
