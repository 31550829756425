import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { allPass, always, cond, prop, propOr, T } from "ramda";
import { Field } from "formik";
import { DebouncedTextField } from "../FormFields/DebouncedTextField";
import DebouncedCurrencyInputV2 from "../FormFields/DebouncedCurrencyInputV2";
import { PartsStoreSelectField } from "../FormFields/PartsStoreSelectField";
import { createTheme, InputAdornment, Switch, TableCell, TableRow, TextField, ThemeProvider } from "@material-ui/core";
import { EjiItem } from "../../generated/nest-graphql";
import { pipe } from "fp-ts/lib/function";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { BasicAutoCompleteField } from "../FormFields/BasicAutoCompleteField";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import { useShowError, useShowWarning } from "../../redux/slices/snackbar";
import { MAX_VENDOR_UNIT_COST, WARN_UNITS, WARN_VENDOR_UNIT_COST } from "../../lib/constants";

const useStyles = makeStyles({
  numberInput: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
const theme = createTheme({
  overrides: {
    MuiSwitch: {
      track: {
        // unchecked coloring
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          // checked coloring
          opacity: 1,
          backgroundColor: "#3CBC45",
        },
      },
    },
  },
});

export type ProductSelectionOption = {
  id: string;
  partId: string;
  label: string;
  value: string;
  vendorUnitCost?: any;
  type?: string;
};

type ProductRowProps = {
  product: EjiItem;
  prodIdx: number;
  taxable: boolean;
  serviceIdx: number;
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
};

const ProductRow = ({
  product,
  prodIdx,
  serviceIdx,
  partsStores,
  taxable,
  ejiType,
  calculatePossibleEJIPriceInfo,
  parts,
}: ProductRowProps) => {
  const classes = useStyles();
  const showWarning = useShowWarning();
  const showError = useShowError();

  const DisabledField = () => <div className="pl-4">--</div>;
  return (
    <TableRow key={product.id}>
      <TableCell style={{ fontSize: "1rem" }}>{product.type}</TableCell>
      <TableCell>
        {product.category === "Labor" ? (
          <DisabledField />
        ) : (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].productSelection.name`}>
            {({ field: { name, value, ...rest }, meta: { error }, form: { values, setFieldValue } }: any) => (
              <BasicAutoCompleteField
                value={{ value: value, label: value }}
                error={error}
                rest={rest}
                options={parts.filter((option) => option?.type === product?.type)}
                required={true}
                onChange={(_, newValue: ProductSelectionOption | string) => {
                  if (typeof newValue === "string") {
                    setFieldValue(name, newValue);
                  } else {
                    const value = propOr(null, "value", newValue);
                    const part = propOr(null, "id", newValue);
                    const vendorUnitCost = propOr(null, "vendorUnitCost", newValue);
                    setFieldValue(name, value);
                    if (vendorUnitCost !== null) {
                      let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                      servicesCopy[serviceIdx].items[prodIdx].vendorUnitCost = vendorUnitCost;
                      servicesCopy[serviceIdx].items[prodIdx].productSelection.name = value;
                      servicesCopy[serviceIdx].items[prodIdx].productSelection.part = part;
                      calculatePossibleEJIPriceInfo({
                        variables: {
                          calculatePossibleEJIPriceInfoInput: {
                            pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                            services: servicesToPossibleEjiServiceInput(servicesCopy),
                            discounts: discountsToEjiDiscountInput(values.discounts),
                            promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                            marketName: values.market,
                            taxable: taxable,
                            calculateAllServices: ejiType === "INVOICE",
                          },
                        },
                      });
                    }
                  }
                }}
              />
            )}
          </Field>
        )}
      </TableCell>
      <TableCell style={{ paddingBottom: "3px" }}>
        {product.category !== "Fee" ? (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].units`}>
            {({ field: { value }, meta: { error }, form: { values } }) => {
              const endAdornment = (
                <InputAdornment position="end" className="text-xs pr-0">
                  {product.category === "Labor" ? "hrs" : "qty"}
                </InputAdornment>
              );
              return (
                <TextField
                  value={value}
                  fullWidth
                  required
                  variant="outlined"
                  onChange={(e) => {
                    const newUnits = Number(e.target.value);
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[prodIdx].units = newUnits;
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput(servicesCopy),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          marketName: values.market,
                          taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                    if (newUnits > WARN_UNITS) {
                      showWarning({ message: "Units is higher than normal" });
                    }
                  }}
                  error={error}
                  type="number"
                  className={classes.numberInput}
                  InputProps={{
                    inputProps: { min: 0, step: "any" },
                    endAdornment: endAdornment,
                  }}
                />
              );
            }}
          </Field>
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        <Field name={`services[${serviceIdx}].items[${prodIdx}].customerPrice`}>
          {({ field: { value, name }, meta: { error }, form: { values, setFieldValue } }) => {
            const targetProduct = values?.services[serviceIdx]?.items[prodIdx];
            const markupText = cond([
              [
                allPass([
                  () => targetProduct.category === "Labor",
                  () => targetProduct?.marketLaborRateUsed !== null,
                  () => targetProduct?.marketLaborRateUsed !== undefined,
                  () => !targetProduct?.overriddenCustomerPrice,
                ]),
                always(`${targetProduct?.marketLaborRateUsed}/hr`),
              ],
              [
                allPass([
                  () => targetProduct?.marketVendorUnitCostMultiplierUsed !== null,
                  () => targetProduct?.marketVendorUnitCostMultiplierUsed !== undefined,
                  () => !targetProduct?.overriddenCustomerPrice,
                ]),
                always(`${targetProduct?.marketVendorUnitCostMultiplierUsed}x`),
              ],
              [T, always("-")],
            ])();
            const endAdornment = targetProduct.category !== "Fee" && (
              <InputAdornment
                position="end"
                className="text-xs pr-0 flex-col"
                style={{ height: "100%", maxHeight: "100%" }}
              >
                <Field name={`services[${serviceIdx}].items[${prodIdx}].overriddenCustomerPrice`}>
                  {({ field: { value }, form: { setFieldValue, values } }) => (
                    <ThemeProvider theme={theme}>
                      <Switch
                        checked={!value}
                        color="default"
                        onChange={(val) => {
                          if (!val.target.checked) {
                            setFieldValue(
                              `services[${serviceIdx}].items[${prodIdx}].overriddenCustomerPrice`,
                              !val.target.checked
                            );
                          } else {
                            const servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                            servicesCopy[serviceIdx].items[prodIdx].overriddenCustomerPrice = false;
                            calculatePossibleEJIPriceInfo({
                              variables: {
                                calculatePossibleEJIPriceInfoInput: {
                                  pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                                  services: servicesToPossibleEjiServiceInput(servicesCopy),
                                  discounts: discountsToEjiDiscountInput(values.discounts),
                                  promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                                  marketName: values.market,
                                  taxable: taxable,
                                  calculateAllServices: ejiType === "INVOICE",
                                },
                              },
                            });
                          }
                        }}
                        size="small"
                      />
                    </ThemeProvider>
                  )}
                </Field>
                <div>{markupText}</div>
              </InputAdornment>
            );
            return (
              <Fragment>
                <DebouncedCurrencyInputV2
                  name={name}
                  value={value}
                  error={error}
                  disabled={targetProduct.category !== "Fee" && !targetProduct?.overriddenCustomerPrice}
                  onChange={(newCustomerPrice, values) => {
                    setFieldValue(name, newCustomerPrice);
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[prodIdx].customerPrice = newCustomerPrice;
                    if (newCustomerPrice !== null) {
                      servicesCopy[serviceIdx].items[prodIdx].overriddenCustomerPrice = true;
                    }
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput(servicesCopy),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          marketName: values.market,
                          taxable: taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  label=""
                  endAdornment={endAdornment}
                />
              </Fragment>
            );
          }}
        </Field>
      </TableCell>
      <TableCell>
        {product.category === "Part" ? (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].vendorUnitCost`}>
            {({ field: { value, name }, meta: { error } }) => {
              return (
                <DebouncedCurrencyInputV2
                  name={name}
                  value={value}
                  error={error}
                  onChange={(newVendorUnitCost, values) => {
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[prodIdx].vendorUnitCost = newVendorUnitCost;
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput(servicesCopy),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          marketName: values.market,
                          taxable: taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                    if (Number(newVendorUnitCost) >= MAX_VENDOR_UNIT_COST) {
                      showError({ message: `Vendor Parts Cost must be under $${MAX_VENDOR_UNIT_COST}` });
                    } else if (Number(newVendorUnitCost) > WARN_VENDOR_UNIT_COST) {
                      showWarning({ message: "Vendor Parts Cost is higher than normal" });
                    }
                  }}
                  label=""
                />
              );
            }}
          </Field>
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        {product.category === "Part" ? (
          <DebouncedTextField name={`services[${serviceIdx}].items[${prodIdx}].orderItem.partNumber`} label="" />
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        {product.category === "Part" ? (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].orderItem.partsStore`}>
            {({ field: { name, value }, meta: { error }, form: { setFieldValue } }: any) => (
              <PartsStoreSelectField
                name={name}
                label={""}
                value={value}
                required={false}
                onChange={(_: any, newValue: any) => {
                  setFieldValue(name, prop("value", newValue));
                }}
                error={error}
                options={partsStores}
              />
            )}
          </Field>
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        <DebouncedTextField name={`services[${serviceIdx}].items[${prodIdx}].notes`} label="" />
      </TableCell>
      <TableCell>
        <Field name={`services[${serviceIdx}].items`}>
          {({ form: { values } }) => (
            <IconButton
              onClick={() => {
                let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                servicesCopy[serviceIdx].items = servicesCopy[serviceIdx].items.filter((_, idx) => idx !== prodIdx);
                calculatePossibleEJIPriceInfo({
                  variables: {
                    calculatePossibleEJIPriceInfoInput: {
                      pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                      services: servicesToPossibleEjiServiceInput(servicesCopy),
                      discounts: discountsToEjiDiscountInput(values.discounts),
                      promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                      marketName: values.market,
                      taxable: taxable,
                      calculateAllServices: ejiType === "INVOICE",
                    },
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Field>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
