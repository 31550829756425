import React, { Fragment, useState } from "react";
import { PossibleEjiItem, PossibleEjiService } from "../../generated/nest-graphql";
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Box } from "@material-ui/core";
import { Button } from "../Buttons/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "formik";
import ProductRow, { ProductSelectionOption } from "./ProductRow";
import { pipe } from "fp-ts/lib/function";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";

type ProductsExpansionProps = {
  serviceIdx: number;
  taxable: boolean;
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
  serviceList: PossibleEjiService[];
};

type AddProductProps = {
  serviceIdx: number;
  serviceName: string;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  taxable: boolean;
  partsStore: any;
  serviceList: PossibleEjiService[];
};

const ProductsExpansionSection = ({
  serviceIdx,
  partsStores,
  ejiType,
  calculatePossibleEJIPriceInfo,
  parts,
  taxable,
  serviceList,
}: ProductsExpansionProps) => {
  return (
    <Box margin={1} padding={2}>
      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell>
              Product
              <br />
              Type
            </TableCell>
            <TableCell className={"w-1/6"}>Selection</TableCell>
            <TableCell style={{ width: "7%" }}>Units</TableCell>
            <TableCell style={{ width: "10%" }}>Customer Price</TableCell>
            <TableCell className={"w-1/12"}>Vendor Unit Cost</TableCell>
            <TableCell style={{ width: "15%" }}>Part #</TableCell>
            <TableCell className={"w-1/6"}>Parts Store</TableCell>
            <TableCell>Private Notes</TableCell>
            <TableCell style={{ width: "3%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Field name={`services[${serviceIdx}].items`}>
            {({ field: { value: products = [] } }) => (
              <Fragment>
                {products.map((product, prodIdx) => (
                  <ProductRow
                    taxable={taxable}
                    product={product}
                    prodIdx={prodIdx}
                    serviceIdx={serviceIdx}
                    partsStores={partsStores}
                    ejiType={ejiType}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                    parts={parts}
                  />
                ))}
              </Fragment>
            )}
          </Field>
        </TableBody>
      </Table>
      <Field name={`services[${serviceIdx}].name`}>
        {({ field: { value }, form: { values } }) => (
          <AddProduct
            taxable={taxable}
            serviceIdx={serviceIdx}
            serviceName={value}
            ejiType={ejiType}
            calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
            partsStore={!values.partsOrdered ? values?.technician?.homePartsStore : null}
            serviceList={serviceList}
          />
        )}
      </Field>
    </Box>
  );
};

type ProductOption = {
  label: string;
  value: PossibleEjiItem;
};

const AddProduct = ({
  serviceIdx,
  serviceName,
  ejiType,
  calculatePossibleEJIPriceInfo,
  taxable,
  partsStore,
  serviceList,
}: AddProductProps) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductOption | null>(null);
  const productList: PossibleEjiItem[] = serviceList
    .find(({ name }) => name === serviceName)
    ?.items.map((item) => ({
      ...item,
      orderItem: {
        ...item.orderItem,
        partsStore: partsStore ?? null,
      },
    }));
  const productsToOptions = (productList = []) => {
    return productList.map((product) => {
      return {
        label: product.type,
        value: product,
      };
    });
  };

  return (
    <div className="flex items-center my-4">
      <Autocomplete
        value={selectedProduct}
        onChange={(_, selectedOption: ProductOption) => {
          setSelectedProduct(selectedOption);
        }}
        options={productsToOptions(productList)}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Product" variant="outlined" />}
      />
      <Box ml={2}>
        <Field name={`services[${serviceIdx}].items`}>
          {({ form: { values } }) => (
            <Button
              onClick={() => {
                if (!!selectedProduct) {
                  const partsStore = values.partsOrdered ? null : values?.technician?.homePartsStore;
                  let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                  const ejiProduct: ProductOption["value"] = {
                    ...selectedProduct.value,
                    orderItem: { ...selectedProduct.value.orderItem, partsStore },
                  };
                  servicesCopy[serviceIdx].items.push(ejiProduct);
                  calculatePossibleEJIPriceInfo({
                    variables: {
                      calculatePossibleEJIPriceInfoInput: {
                        pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                        services: servicesToPossibleEjiServiceInput(servicesCopy),
                        discounts: discountsToEjiDiscountInput(values.discounts),
                        promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                        marketName: values.market,
                        taxable: taxable,
                        calculateAllServices: ejiType === "INVOICE",
                      },
                    },
                  });
                  setSelectedProduct(null);
                }
              }}
              type={"button"}
            >
              + Add Product
            </Button>
          )}
        </Field>
      </Box>
    </div>
  );
};

export default ProductsExpansionSection;
